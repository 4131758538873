import React from 'react';
import styles from './buttonoptionsitem.module.css';

import Icons from '../../1atoms/icons/Icons';


export default function ButtonOptionItem(props) {
  return (
    <button type='button' className={styles.item} onClick={props.click} >
       {props.icon && <Icons type='utility' icon={props.icon} size='small'/>} 
       {props.children}
    </button>
  )
}
