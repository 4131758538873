import { useState, createContext, useEffect} from "react";

// Firebase
import { db } from "../firebase/firebase";
import { doc, onSnapshot } from 'firebase/firestore';
import { getAuth } from "firebase/auth";

export const TeacherContext = createContext();

export default function TeacherProvider({ children }) {

  const [data, setTeacherData] = useState();
  const [loading, setLoading] = useState('');

  const auth = getAuth();

   // Teacher Database
   useEffect(() => {
    setLoading(true)
    const teacherPath = doc(db, `teachers/${auth.currentUser.uid}`);
    const unsub = onSnapshot(teacherPath, (doc) => {
      if (doc.exists) {
        const docData = doc.data();
        setTeacherData(docData);
        setTimeout(() => {
          setLoading(false); 
        }, "200") 
      }

    });
    
    return unsub;   
    
  }, [auth])

  return (
    <TeacherContext.Provider value={{data, loading}}>
      {children}
    </TeacherContext.Provider>
  );
}
