import React from 'react';
import styles from './panelaction.module.css';

// Components
import Panel from '../../2molecules/panel/Panel';
import PanelHeader from '../../2molecules/panelheader/PanelHeader';
import PanelReward from '../panelreward/PanelReward';
import PanelStore from '../panelstore/PanelStore';
import PanelSEL from '../panelsel/PanelSEL';

export default function PanelAction({state}) {

  return (

  <Panel sticky >
  <PanelHeader state={state} />

    <div className={styles.body}>

      {state==='Reward' && <PanelReward state={state}/>}
      {state==='Store' && <PanelStore state={state} />}
      {state==='SEL' && <PanelSEL state={state} />}
 
    </div> 

  </Panel>

    
  );
}