import React, {useState, useEffect} from 'react'
import styles from './image.module.css';


export default function Image({img, type, size, loading}) {

    // Set Style
    let typestyle;
    switch (type) {
    case 'profile':
        typestyle = styles.profile;
        break;
    case 'item':
        typestyle = styles.item;
        break;
    default:
        typestyle = styles.item;
    }

    let sizestyle;
    switch (size) {
    case 'tiny':
        sizestyle = styles.tiny;
        break;
    case 'small':
        sizestyle = styles.small;
        break;
    case 'medium':
        sizestyle = styles.medium;
        break;
    default:
        sizestyle = styles.medium;
    }


  return (
    <div className={styles.container}>{ 
        loading===true ? 
        <div className={`${styles.loading} ${sizestyle} ${typestyle}`}></div> 
        : img ? 
        <img className={`${sizestyle} ${typestyle}`} src={img}/> 
        : loading===false ?
        <img className={`${sizestyle} ${typestyle}`} src='../../../images/system-no-image.png'/> 
        : 
        <div className={`${styles.loading} ${sizestyle} ${typestyle}`}></div>
        }
    </div>
  )
}
