import React from "react";
import styles from './button.module.css';

export default function Button(props) {

  // let style;

  // switch (props.type) {
  //   case 'primary':
  //       style = styles.primary;
  //       break;
  //   case 'secondary':
  //       style = styles.secondary;
  //       break;
  //   case 'icon':
  //       style = styles.icon;
  //   }

  return (
    <button 
    type={props.type2}
      id={props.id}
      onClick={props.click} 
      className={`
        ${styles.button}
        ${
          props.state==='loading' ? styles.loading :
          props.type==='primary' ? styles.primary : 
          props.type==='text' ? styles.text : 
          props.type==='secondary' ? styles.secondary : 
          props.type==='icon' ? styles.icon : 
          styles.secondary 
        }

        ${props.purpose==='create' && styles.create}

        ${ props.size==='small' && styles.small}

        ${ props.class && props.class }
        ${
          props.state==='Reward' ? styles.reward : 
          props.state==='Store' ? styles.store : 
          props.state==='SEL' ? styles.sel : 
        ''}
      `}>

      {props.state==='loading' ? 'WORKING' : 
      props.children
      }
       
    </button>
  );
}
