import React, {useState, useEffect} from "react";
import styles from './header.module.css';

import { useNavigate, NavLink } from "react-router-dom";

//Firebase
import { getAuth, signOut } from "firebase/auth";
import { db } from "../../../firebase/firebase";
import { doc, onSnapshot, collection, query } from 'firebase/firestore';

// Components
import Logo from '../../1atoms/logo/Logo';
import Icons from "../../1atoms/icons/Icons";
import MenuItem from "../../2molecules/menuItem/menuItem";
import Button from '../../2molecules/button/Button';
import Image from "../../2molecules/image/Image";
import TypeSelection from "../typeselection/TypeSelection";




export default function Header({type, state, setActiveState}) {

  const auth = getAuth();
  const navigate = useNavigate();

  // Log Out
  function logout() {
    signOut(auth).then(() => {
      navigate('/signin');
    }).catch((error) => {
      console.log(error);
    });
  }

  // Set Staff Image
  const [staffImage, setStaffImage] = useState('');
  const [staffDoc, setStaffDoc] = useState('');

   // Current Image URL from Database
   useEffect(() => {
    const teacherPath = doc(db, `teachers/${auth.currentUser.uid}`);
    const unsub = onSnapshot(teacherPath, (doc) => {
      if (doc.exists) {
        const docData = doc.data();
        setStaffDoc(docData);
        if (docData.img) {
          setStaffImage(docData.img);
        } else {
          setStaffImage('../../../images/system-no-image.png');
        }
      }

    });
    return unsub;   
}, [auth]);

 // Groups
 const [groupListing, setGroupListing] = useState([]);

 useEffect(() => {
   const q = query(collection(db, `teachers/${auth.currentUser.uid}/groups/`));
   const unsubscribe = onSnapshot(q, (querySnapshot) => {
   const groups = [];
   querySnapshot.forEach((doc) => {
 
       groups.push({data: doc.data()});
       setGroupListing(groups);
       
   });
   return unsubscribe;
  
   });
   
 }, []);


  // Drawers
  const [setDrawer, setIsDrawer] = useState(false);
  const [setUserTypeDrawer, setIsUserTypeDrawer] = useState(false);


  return (
    <>
    <header className={styles.mainHeader}>

    <div className={styles.mobileUserTypeMenu}>
      <Button type="icon" click={() => setIsUserTypeDrawer(!setUserTypeDrawer)} >
        {setUserTypeDrawer===true ? 
          <Icons type="utility" size='large' icon='xmark'/> : 
          <Icons type="utility" size="small" icon="bars"  />
        }
      </Button>  
    </div>

    {setUserTypeDrawer===true &&  
      <div className={`${styles.drawerContainer} ${styles.drawerLeft}`}>
      
        <TypeSelection groups={groupListing} dragdrop={false} />
       
        
      </div>
    }

    <NavLink to="/feed">
      <Logo style={styles.desktopLogo} state="fullLogo"/>
      <Logo style={styles.mobileLogo} state="iconOnly"/>
    </NavLink>

{type==='Student' ? 
    <nav className={styles.mainNav}>
      <MenuItem
        setActiveState={menuFunctionState => setActiveState(menuFunctionState) }
        item={{
          icon: 'heart',
          id: '1',
          label: 'SEL',
          state:  state==='SEL' ? 'ACTIVE' : 'DEFAULT',
        }}
      /> 
      <MenuItem
        setActiveState={menuFunctionState => setActiveState(menuFunctionState) }
        item={{
          icon: 'star',
          id: '2',
          label: 'Reward',
          state: state==='Reward' ? 'ACTIVE' : 'DEFAULT',
        }}
      />
      <MenuItem
        setActiveState={menuFunctionState => setActiveState(menuFunctionState) }
        item={{ 
          icon: 'money-bill-1',
          id: '3',
          label: 'Store',
          state: state==='Store' ? 'ACTIVE' : 'DEFAULT',
        }}
      />
        
    </nav>
   : type==='Feed' ? 
     <nav className={styles.mainNav}>

    </nav> 

   : type==='Teacher' ? 
    <nav className={styles.mainNav}>
      <MenuItem
        setActiveState={menuFunctionState => setActiveState(menuFunctionState) }
        item={{
          icon: 'bolt',
          id: '1',
          label: 'Overview',
          state: state==='Overview' ? 'ACTIVE' : 'DEFAULT',
        }}
      />
      <MenuItem
        setActiveState={menuFunctionState => setActiveState(menuFunctionState) }
        item={{
          icon: 'message',
          id: '2',
          label: 'Messages',
          state: state==='Message' ? 'ACTIVE' : 'DEFAULT',
        }}
      />
      <MenuItem
        setActiveState={menuFunctionState => setActiveState(menuFunctionState) }
        item={{
          icon: 'message',
          id: '2',
          label: 'Tic Tac Toe',
          state: state==='tic-tac-toe' ? 'ACTIVE' : 'DEFAULT',
        }}
      />
  
    </nav> 

   : null}

      <Button class={`${styles.button} ${setDrawer===true && styles.iconButton}`} type="icon" click={() => setIsDrawer(!setDrawer)} >
      {setDrawer===true ? 
        <Icons type="utility" size='large' icon='xmark'> </Icons> : 
        <Image type='profile' size='small' img={staffImage}/>  
      }
      </Button>

    </header>

    {setDrawer===true &&  
      <div className={styles.drawerContainer}>
        <NavLink to="/teacher-profile"><p>Your Profile</p></NavLink>
        <p>School Profile</p>
        {staffDoc.role==='admin' && <NavLink to="/admin"><p>Admin</p></NavLink>}
        <Button click={() => logout()}>Sign Out</Button>
      </div>
    }

    </>
  );
}
