import React, {useState, useEffect} from 'react';
import styles from './teacherlist.module.css';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import CardTeacher from '../../2molecules/cardteacher/CardTeacher';

export default function TeacherList({teachers, state}) {


  // All Teachers
  const [isSelected, setIsSelected] = useState('');

  // All Teachers - No Groups Yet
  const [selectTeachers, setSelectTeachers] = useState([]);

  useEffect(() => {
  setSelectTeachers(teachers);
  }, [teachers]);

  // console.log(teachers);

 

  


  return (
    <>
    <DndProvider backend={HTML5Backend}>
   
      <div className={styles.grid}>
      

        {selectTeachers.map((teacher, i) =>  (

                <CardTeacher teacher 
                  state={state} 
                  key={i} 
                  id={i}  
                  selected={isSelected}
                  >

                    <img alt={`${teacher.data.firstName} ${teacher.data.lastName}`} src={teacher.data.img}/> 
                   
                    <p><strong>{teacher.data.firstName} {teacher.data.lastName}</strong></p>

                    {state==='Information' ? 
                      <p>
                      
                      <span className='details'>{teacher.data.role}</span>
                      </p> 
                      : state==='Message' ?
                      <p>
                      Last Message<br/>  
                      
                      </p> 
                    : null }

                  
                </CardTeacher>
              
              
              
      )
       )}
      
      </div>
      </DndProvider>
     </>
    
  );


}