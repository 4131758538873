import React from 'react';
import {Outlet, Navigate} from 'react-router-dom';

import {useEarlyAccessStatus} from '../hooks/useEarlyAccessStatus';

export default function EarlyAccessRoute() {

  const {earlyAccess, checkingAccess} = useEarlyAccessStatus();

    if (checkingAccess) {
        return <p>loading...</p>;
    }

  return earlyAccess ? <Outlet/> : <Navigate to='/stayupdated'/>
}
