import React from 'react'
import Icons from '../../1atoms/icons/Icons';
import Image from '../image/Image';
import styles from './carditem.module.css';

export default function CardItem(props) {
  return (
    <div className={styles.container} >
    {props.options}
     <article className={`${styles.card}`}>
        {props.checkbox}
        <div className={styles.label}>
          <Image type="item" size="small" img={props.image}/>
          <label htmlFor={props.id}>{props.label}</label>
        </div>
        <div className={styles.cost}>
          <Icons icon="Reward" size="medium"/><p>-{props.cost}</p>
        </div>
    </article>
    </div>
  )
}
