import React, {useState, useEffect} from "react";
import styles from './cardteacher.module.css';
import { ItemTypes } from '../../../context/constants';
import { useDrag } from 'react-dnd';



export default function CardTeacher(props) {

  
    const [{isDragging}, drag] = useDrag(() => ({
      type: ItemTypes.TEACHER,
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    }))

  // Student Card Selection
  const [isSelected, setSelected] = useState();

  function cardClick() {
    setSelected(!isSelected)
  }

  useEffect(() => {
    
    if (props.click===true) {
      setSelected(true); 
    } else if (props.click===false) {
      setSelected(false);
    } else if (props.selected===false) {
      // Currently being used to clear card selection about an action
      setSelected(false);
    } else if (props.selected===true) {
      setSelected(true);
    }
  }, [props.click, props.selected]);


  // let o = document.querySelectorAll(".card");
  // document.querySelectorAll("#teacherPage").on("mousemove", function (t) {
  //     let e = -(window.innerWidth() / 2 - t.pageX) / 30,
  //         n = (window.innerHeight() / 2 - t.pageY) / 10;
  //     o.attr("style", "transform: rotateY(" + e + "deg) rotateX(" + n + "deg);-webkit-transform: rotateY(" + e + "deg) rotateX(" + n + "deg);-moz-transform: rotateY(" + e + "deg) rotateX(" + n + "deg)")
  // })
 
  return (
    <article 
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        
      }}
      id={props.id}
      onClick={cardClick} 
     
      
      className={`
        card 
        ${styles.card} 
        ${props.teacher===true ? styles.teacher : null}
       
        ${isSelected===true ? styles.selected : null}
      `}>

      {props.children}
       
    </article>
  );
  
}
