import React, { useEffect, useState } from 'react';
import styles from './studentpage.module.css';

// Components
import Header from '../../3organisms/header/Header';
import ListStudents from '../../3organisms/liststudents/ListStudents';
import PanelAction from '../../3organisms/panelaction/PanelAction';
import MainPanel from '../../4templates/mainPanel/MainPanel';

// Context
import FormProvider from "../../../context";

// Firebase
import { db } from "../../../firebase/firebase";
import { collection, onSnapshot, where, query } from "firebase/firestore";


export default function StudentPage() {
  
  document.title = 'all students';

  

  // Offers
  const [offerListings, setOfferListings] = useState(null);
  // useEffect(() => {
  //   async function fetchListings() {
  //     try {
  //       // get reference
  //       const listingsRef = collection(db, "students");
  //       // create the query
  //       const q = query(
  //         listingsRef,
  //         where("idschool", "==", "ZrewvPrq457M6f8AVI0T")
  //       );
  //       // execute the query
  //       const querySnap = await getDocs(q);
  //       const listings = [];
  //       querySnap.forEach((doc) => {
  //         return listings.push({
  //           id: doc.id,
  //           length: 0,
  //           data: doc.data()
  //         });
  //       });
  //       setOfferListings(listings);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   fetchListings();
  // }, []);

  useEffect(() => {
    const q = query(collection(db, "students"), where("idschool", "==", "ZrewvPrq457M6f8AVI0T"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const students = [];
    querySnapshot.forEach((doc) => {
  
        students.push({data: doc.data(), id: doc.id});
        setOfferListings(students);
        
    });
    return unsubscribe;
   
    });
    
  }, []);




  // Menu State
  const [activeState, setActiveState] = useState('SEL');

  




  return (
    <FormProvider> 
      <div className="page lists-show">
        <Header type='Student' state={activeState} setActiveState={setActiveState} />
        <div className={styles.section}> 
          <MainPanel 
            cards={<ListStudents state={activeState} students={offerListings} group='all'/>} 
            panel={<PanelAction state={activeState} />}
          />
        </div>
      </div>
    </FormProvider>
  );
}