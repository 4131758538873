import React, {useState} from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';

// Firebase
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";

// Components
import StartTemplate from '../../4templates/startTemplate/StartTemplate';
import Button from '../../2molecules/button/Button';

export default function SignInPage() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState('');
  const [firebaseErrors, setFirebaseErrors] = useState(false);

  const { register, handleSubmit: handleSubmit, formState: {errors}} = useForm();

  const onSubmit = (async (values) => {
    setFirebaseErrors(false);
    setLoading('loading');
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
      if (userCredential.user) {
        navigate('/feed');
      }    
    } catch (error) {
      console.log(error);
      setFirebaseErrors(true);
    }
    setLoading('');
  })

  document.title = 'sign in';

  return (
    <StartTemplate title={'sign in'} > 
      <form className="panelForm" autoComplete={"off"}>

          <div className="formContainer">
              <label>School Email:</label>
              <input 
                autoComplete={'rand0mstr1nG'} 
                type='email' 
                {...register('email', { required: true })} 
              />
              {errors.email ? <div className="error">Must enter email address.</div> : 
              firebaseErrors ? <div className="error">Please check email</div> : 
              null }
          </div>

          <div className="formContainer">
              <label>Password:</label>
              <input 
                autoComplete={'rand0mstr1nG'} 
                type='password' 
                {...register('password', { required: true })}  
              />
              {errors.password ? <div className="error">Must enter password.</div> : 
              firebaseErrors ? <div className="error">Please check password</div> : 
              null }
          </div>

          <Button type={'primary'} click={handleSubmit(onSubmit)} state={loading}>Login</Button>
          <br/>
          <NavLink style={{textAlign: 'center'}} to="../password-reset">Forgot Password?</NavLink> 

      </form>

    </StartTemplate>
  )
}
