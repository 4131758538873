import React, {useState} from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// components
import Modal from '../../2molecules/modal/Modal';
import Button from '../../2molecules/button/Button';

// Firebase
import { db } from "../../../firebase/firebase";
import { addDoc, updateDoc, setDoc, doc, collection, serverTimestamp, arrayUnion } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import AddImageGroup from '../addImageGroup/AddImageGroup';


export default function ModalAddGroup() {

  const { register, handleSubmit, formState: {errors}, reset} = useForm();
  const auth = getAuth();
  const navigate = useNavigate();
  
  const groupRef = doc(collection(db, `teachers/${auth.currentUser.uid}/groups`));
  
  const [imageStatus, setImageStatus] = useState(0);
  const [profileImg, setProfileImg] = useState('../../../images/system-no-image.png');
  const [profileImgName, setProfileImgName] = useState('');
  const [newStudentID, setNewStudentID] = useState('');
  const [loading, setLoading] = useState('');

  const [firebaseErrors, setFirebaseErrors] = useState(false);

    const onSubmit = (async (values) => {

        const studentNewRef = doc(db, `teachers/${auth.currentUser.uid}/groups/${newStudentID}`);

        setFirebaseErrors(false);
        setLoading('loading');
        
        try {

            // New Group
            const formNewGroup = {...values};
            formNewGroup.addedbywhen = serverTimestamp();
            formNewGroup.addedbyid = auth.currentUser.uid;
            formNewGroup.addedbyname = auth.currentUser.displayName;
            formNewGroup.idgroup = newStudentID;
            formNewGroup.img = profileImg;
            formNewGroup.imgname = profileImgName;
            formNewGroup.students = [];
 
            await setDoc(studentNewRef, formNewGroup);

            // const schoolPath = doc(db, 'schools/ZrewvPrq457M6f8AVI0T');
            // await updateDoc(schoolPath, {students: arrayUnion(studentRef.id),});

        } catch (error) {
            console.log(error);
            setFirebaseErrors(true);
        }
        setImageStatus(imageStatus + 1);
        reset(); 
        setLoading('');
        window.createGroupModal.close();
        navigate('/students');
        
    })

  return (

    <Modal 
        title="Add Group" 
        modalid='createGroupModal'
        button={<Button type={'primary'} click={ handleSubmit(onSubmit) } state={loading}>Add Group</Button>}
    >

    <AddImageGroup 
      imageStatus={imageStatus} 
      setProfileImg={setProfileImg} 
      setNewStudentID={setNewStudentID} 
      setProfileImgName={setProfileImgName} 
      groupid={groupRef.id} 
      teacherid={auth.currentUser.uid}
    />
    
    <form className="panelForm" autoComplete={"off"}>

        <div className="formContainer">
            <label>Group Name:</label>
            <input 
            
            type='text' 
            {...register('namegroup', { required: true })} 
            />
            {errors.namegroup ? <div className="error">Must enter group name.</div> : 
            firebaseErrors ? <div className="error">Please check entry.</div> : 
            null }
        </div>

    </form>

   

    </Modal>
  )
}
