import React, {useState} from 'react';
import styles from './modalwelcometeacher.module.css';

import { useForm } from 'react-hook-form';
import imageCompression from 'browser-image-compression';

// Firebase
import { db } from "../../../firebase/firebase";
import { setDoc, serverTimestamp, updateDoc, doc, collection  } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

//Components
import Modal from '../../2molecules/modal/Modal';
import Button from '../../2molecules/button/Button';




export default function ModalWelcomeTeacher({auth}) {

    const storage = getStorage();

    const [loading, setLoading] = useState(false);

     // Image states
     const [previewImage, setPreviewImage] = useState('');
     const [uploadImage, setUploadImage] = useState('');
     const [nameImage, setNameImage] = useState('');

     // Image Preview
    const changeHandler = (e) => {
        let preview = URL.createObjectURL(e.target.files[0]);
        setPreviewImage(preview);
        let upload = e.target.files[0];
        setUploadImage(upload);
        
    }

    // Submit Teacher Role Img and Login Number
    const { register, handleSubmit, formState: {errors}, reset} = useForm();

    const onSubmit = (async (values) => {

        setLoading('loading');

        try {

            const teacherRef = doc(db, `teachers/${auth.currentUser.uid}`); 
            const schoolPostRef = doc(collection(db, `schools/ZrewvPrq457M6f8AVI0T/posts`)); 
            
            
            if (uploadImage) {     
                
                const teacherStorageRef = ref(storage, `ZrewvPrq457M6f8AVI0T/teachers/${auth.currentUser.uid}/${uploadImage.name}`);
                

                //reduce
                const reduceOptions = {
                    maxSizeMB: .3,
                    maxWidthOrHeight: 600,
                    useWebWorker: true
                }
                imageCompression(uploadImage, reduceOptions).then(function (compressedFile){


                    const uploadTeacherImg = uploadBytesResumable(teacherStorageRef, compressedFile);
                    setNameImage(uploadImage.name);
                

                uploadTeacherImg.on('state_changed', (snapshot) => {
                    console.log('uploaded');
                },(error) => {
                    console.log(error);
                }, async () => {
                    getDownloadURL(uploadTeacherImg.snapshot.ref).then((downloadURL) => {
                        
                        const teacherNewProfile = {...values};
                        teacherNewProfile.img = downloadURL;
                        teacherNewProfile.imgname = nameImage;
                        teacherNewProfile.login = 1;
                        updateDoc(teacherRef, teacherNewProfile);

                        const formNewPost = {};
                        formNewPost.addedbywhen = serverTimestamp();
                        formNewPost.type = 'welcome';
                        formNewPost.addedbyid = auth.currentUser.uid;
                        formNewPost.addedbyname = auth.currentUser.displayName;
                        formNewPost.teacherimg = downloadURL;
                        formNewPost.postimg = '../../../images/post-welcome-background.png';
                        formNewPost.postimgname = 'post-welcome-background.png';
                        formNewPost.post = `Welcome to the school ${auth.currentUser.displayName}`;
                        setDoc(schoolPostRef, formNewPost);

                        
                    });
                });

            })
                       
                        
    
                } else {
    
                    console.log('no image');
    
                }
    
            
            
      
          } catch (error) {
              console.log(error);       
          }
          reset();  
          setLoading('');
          setUploadImage('');
          setPreviewImage('');
          
          window.welcomeTeacherModal.close();

    })





  return (
    <Modal 
        title="Welcome Beta Tester!" 
        modalid='welcomeTeacherModal'
        button={<Button type={'primary'} click={ handleSubmit(onSubmit) } state={loading} >Save</Button>}
        required='required'
    >
  
  <p className={styles.userName}><strong>{auth.currentUser.displayName}</strong></p>

    <form>  

        <div className="formContainer">

             <div className={`formContainer ${styles.imagePreview}`}>

                {previewImage ?
                    <div className={styles.image} style={{ backgroundImage: `url(${previewImage})` }} ></div> : 
                    <div className={styles.image} style={{ backgroundImage: `url('../../../images/system-no-image.png')` }} ></div>
                } 
            </div>

            <div className={styles.buttonImageContainer} >
                <Button type2='button' class={styles.imageButton}>
                    <label className={styles.imageLabelButton} htmlFor="file-upload3">
                        Upload Image
                    </label>
                    <input type='file' id="file-upload3" className={styles.inputFile} onChange={changeHandler}/>
                </Button> 
                
            </div>
        </div>

        <div className="formContainer">
            
            <label>Your Role:</label>
            <select {...register('role', { required: true })}  name="roles" id="roles" >
                <option value="beta tester">Beta Tester</option>
                <option value="beta tester">Teacher</option>
                <option value="beta tester">Admin</option>
                <option value="beta tester">SEL Coach</option>
                <option value="beta tester">Social Worker</option>
                <option value="beta tester">King Potato Salad</option>
            </select>
            {errors.role && <div className="error">Must select a role.</div>}  
        </div>
        
        
    </form>

    </Modal>
    
  )
}
