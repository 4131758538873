import React from 'react';

// Components
import Header from '../../3organisms/header/Header';
import SectionTeacherInfo from '../../3organisms/sectionTeacherInfo/SectionTeacherInfo';
import AddImageTeacherProfile from '../../3organisms/addImageTeacherProfile/addImageTeacherProfile';
import ProfileTemplate from '../../4templates/profileTemplate/ProfileTemplate';



export default function ProfileTeacherPage() {

  document.title = 'your profile';

  

  return (
    <>
        <Header/>
        <ProfileTemplate leftPanel={<AddImageTeacherProfile/>} rightPanel={<SectionTeacherInfo/>}/>
    </>
  )
}
