import React, {useState, useEffect} from "react";
import styles from './cardstudent.module.css';

// Components
import Icons from '../../1atoms/icons/Icons';
import Button from "../button/Button";

// Redux
import { ItemTypes } from '../../../context/constants';

// Drag and Drop
import { useDrag } from 'react-dnd';


export default function CardStudent(props) {



  // Student Card Selection
  // const [isSelected, setSelected] = useState();

  // function cardClick() {
  //   setSelected(!isSelected)
  // }

  // useEffect(() => {
    
  //   if (props.click===true) {
  //     setSelected(true); 
  //   } else if (props.click===false) {
  //     setSelected(false);
  //   } else if (props.selected===false) {
  //     // Currently being used to clear card selection about an action
  //     setSelected(false);
  //   } else if (props.selected===true) {
  //     setSelected(true);
  //   }
  // }, [props.click, props.selected]);

  // Card Styles
  // ${isSelected===true ? styles.selected : null}
  // onClick={props.student===true ? cardClick : null} 



// Student Card icon animation after action
const [isMessage, setMessage] = useState(false);

useEffect(() => {
  if (props.student===true && props.data.done === true) {

    let data = Object.values(props.data);

    if (data[props.id + 1]===true) {
      setMessage(true);
    }
  }
}, [props.data]);



// Card Dragging
const [{ isDragging }, drag] = useDrag(() => ({
  type: ItemTypes.BOX,
  item: {name: props.cardid},
  end: (item, monitor) => {
    const dropResult = monitor.getDropResult()
    if (item && dropResult) {
      // console.log({item})
    }
  },
  collect: (monitor) => ({
    isDragging: monitor.isDragging(),
    handlerId: monitor.getHandlerId(),
  }),
}))
  
const opacity = isDragging ? 0.4 : 1 

  return (
    
    <div className={styles.container} ref={drag} style={{ opacity }}>
    {props.options}
    <article 
      
      // id={props.cardid}
      
      
      className={`
        card 
        ${styles.card} 
        ${props.student===true ? styles.student : null}
        ${props.panel===true ? styles.panel : null}
        
         
        ${
          props.state==='Reward' ? styles.reward : 
          props.state==='Store' ? styles.store : 
          props.state==='SEL' ? styles.sel : 
        ''}
      `}>

      {isMessage===true && <div className={styles.iconCircle}><Icons size="medium" icon='Reward'/></div>}
         
      <Button type="icon" type2="button" class={styles.move} >
        <Icons type='utility' icon='arrows-up-down-left-right' size='small'/> 
      </Button>
      
      {props.children}
       
    </article>
    </div>
    
  );
}
