import React from 'react';
import styles from './tutorial.module.css';

// Components
import Icons from '../../1atoms/icons/Icons';
import Button from '../button/Button';


export default function Tutorial({clear, modalid, button}) {


  return (
    
    
        <dialog id={modalid} className={styles.container}>

            <section className={styles.header}>
                
                
                <form method="dialog">
                    <button className={styles.closeIcon} onClick={clear}>
                        <Icons type='utility' icon='xmark' size='medium'/>
                    </button>
                </form>
                
            </section>

            <section className={styles.body}>
            
            <iframe className={styles.frame} src="../animation/group-add/tutorial-group-add-student.html" title="Add Students to a Group"></iframe>
            
            </section>

            <section className={`${styles.footer} ${styles.center}`}>
            
                <form method="dialog">
                    <Button click={clear} type='secondary'>
                       {button ? 'Cancel' : 'Close'} 
                    </Button>
                </form>
            
            </section>
    
        </dialog>
    
  )
}
