import React, {useState, useEffect} from 'react'

export default function List(props) {

// If zero, over limit, error, loading
const [ready, setReady] = useState(false)
const [message, setMessage] = useState('')
const [over, setOver] = useState(false)

useEffect(() => {
    if (props.loading===true) {
        setMessage('loading')
    } else if (props.loading===false && props.length===0) {
        setMessage(props.none)
    } else if (props.error) {
        setMessage(props.error)
    } else if (props.length>5) {
        setReady(true)
        setMessage('')
        setOver(true)
    } else {
        setReady(true)
        setMessage('')
        setOver(false)
    }
}, [props])


  return (

    <>
    {message}
    {ready===true && props.children}
    {over && 'too many'}
    </>
    

  )
}
