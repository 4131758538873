import React from "react";
import { NavLink, Link } from "react-router-dom";
import styles from './markettemplate.module.css';

import Logo from "../../1atoms/logo/Logo";
import Icons from "../../1atoms/icons/Icons.js";



export default function MarketTemplate(props) {

  

  return (
    <> 
    <header className={`${styles.section} ${styles.noPadding}`}>
      <div className={`${styles.container} ${styles.small}`}>
        <nav className={`${styles.nav} ${styles.desktop}`}> 
        
          <div>
            <NavLink to="../">home</NavLink>
            <NavLink to="../features">features</NavLink>
            {/* <a href="#">benefits</a> */}
          </div>
            <NavLink to="/marketing"><Logo state="fullLogo" size="medium"/> </NavLink>
          <div>
          {/* Should be login */}
            <NavLink to="../signin">sign in</NavLink>
            <NavLink to="../signup" className={styles.button}>sign up </NavLink> 
          </div>
        </nav>

        <nav className={`${styles.nav} ${styles.mobile}`}> 
            <NavLink to="/marketing"><Logo state="fullLogo" size="medium"/> </NavLink>

            <details>
              <summary className={styles.menuIcon}><Icons type="utility" icon="bars"/> </summary>
              <div className={styles.mobileMenu}>
              <NavLink to="../">home</NavLink>
              <NavLink to="../features">features</NavLink>
              <NavLink to="../signin">sign in</NavLink>
              <NavLink to="../signup" className={styles.button}>sign up </NavLink> 
              </div>
            </details>

         
        </nav>
      </div>
    </header>
    <main className={styles.main}> 

          {props.children}

    </main>

    <footer className={styles.section}>
      <div className={`${styles.container} ${styles.noPadding}`}>
        <nav className={styles.nav}> 
          <Logo state="fullWhiteLogo" size="medium"/>
          
          <div>
            <span className={styles.hide}><NavLink to="../signin">sign in</NavLink></span>
            <NavLink to="../signup" className={`${styles.button} ${styles.buttonWhite}`}>sign up </NavLink> 
          </div>
        </nav>
      </div>
      <p className={styles.signature}>Designed by: <a href="https://joevessell.com/" target="_blank">Joe Vessell</a></p>
    </footer>
   
   </>  

  ); 
}
