import React, {useState} from 'react'
import styles from './modaladditem.module.css';

import { useForm } from 'react-hook-form';
import imageCompression from 'browser-image-compression';

// Firebase
import { getAuth } from "firebase/auth";
import { db } from "../../../firebase/firebase";
import { setDoc, doc, serverTimestamp, collection } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Components
import Button from '../../2molecules/button/Button';
import Modal from '../../2molecules/modal/Modal'
import Icons from '../../1atoms/icons/Icons';
import Image from '../../2molecules/image/Image';

export default function ModalAddItem() {

    const auth = getAuth();
    const storage = getStorage();
    const { register, handleSubmit, formState: {errors}, reset} = useForm();

    const [loading, setLoading] = useState('');

    // Image states
    const [previewImage, setPreviewImage] = useState('');
    const [uploadImage, setUploadImage] = useState('');
    const [nameImage, setNameImage] = useState('');

  // Image Preview
  const changeHandler = (e) => {
    let preview = URL.createObjectURL(e.target.files[0]);
    setPreviewImage(preview);
    let upload = e.target.files[0];
    setUploadImage(upload);
  }


    const onSubmit = (async (values) => {

      setLoading('loading');
 
      try {

        const teacherStoreRef = doc(collection(db, `schools/ZrewvPrq457M6f8AVI0T/stores/teachers/${auth.currentUser.uid}`)); 
        
        if (uploadImage) {     
            const teacherStoreStorageRef = ref(storage, `ZrewvPrq457M6f8AVI0T/stores/${auth.currentUser.uid}/${uploadImage.name}`);

            //reduce
            const reduceOptions = {
                maxSizeMB: .3,
                maxWidthOrHeight: 600,
                useWebWorker: true
            }

            imageCompression(uploadImage, reduceOptions).then(function (compressedFile){
                const uploadStoreImg = uploadBytesResumable(teacherStoreStorageRef, compressedFile);
                setNameImage(uploadImage.name);
                uploadStoreImg.on('state_changed', (snapshot) => {
                    console.log('uploaded');
                },(error) => {
                    console.log(error);
                }, async () => {
                    getDownloadURL(uploadStoreImg.snapshot.ref).then((downloadURL) => {
    
                        const formNewItem = {...values};
                        formNewItem.addedbywhen = serverTimestamp();
                        formNewItem.addedbyid = auth.currentUser.uid;
                        formNewItem.addedbyname = auth.currentUser.displayName;
         
                        formNewItem.itemimg = downloadURL;
                        formNewItem.itemimgname = nameImage;

                        setDoc(teacherStoreRef, formNewItem);

                    });
                });
            })

            } else {

            // New Post
            const formNewItem = {...values};
            formNewItem.addedbywhen = serverTimestamp();
            formNewItem.addedbyid = auth.currentUser.uid;
            formNewItem.addedbyname = auth.currentUser.displayName;
            
            formNewItem.postimg = '';
            formNewItem.postimgname = '';

            setDoc(teacherStoreRef, formNewItem);

            }
  
  
      } catch (error) {
          console.log(error);       
      }
      reset();  
      setLoading('');
      setUploadImage('');
      setPreviewImage('');
      
      window.addItemModal.close();


    })

  return (
    <Modal
        title="Add Store Item" 
        modalid='addItemModal'
        button={<Button type={'primary'} click={ handleSubmit(onSubmit) } state={loading}>Add Item</Button>}
    >
    

      <form>  

        <div className="formContainer">
            <div>
            <Button type2="button" type={'secondary'} >
              <label className={styles.label} htmlFor="file-upload">
                Add Image
              </label>
            </Button>
            </div>
            
              <input type='file' id="file-upload" className={styles.inputFile} onChange={changeHandler}/>
              <div id="imagePreview" className="formContainer">
                  {previewImage && <div className={styles.imagePreview} style={{ backgroundImage: `url(${previewImage})` }} ></div>}    
              </div>
        </div>

        <div className="formContainer">
            <label>Label:</label>
            <input {...register('label', { required: true })}  type="text" />
            {errors.label && <div className="error">Must enter a label</div>}

        </div>

        <div className="formContainer">
            <label>Cost:</label>
            <input {...register('cost', { required: true, valueAsNumber: true })}  type="number" />
            {errors.cost && <div className="error">Must enter a cost</div>}

        </div>

        <div className="formContainer">
            <label>Stock:</label>
            <input {...register('stock', { valueAsNumber: true  })}  type="number" />
        </div>
        
    </form>
    
        
    </Modal>
  )
}
