import React, { Children } from "react";
import { NavLink } from "react-router-dom";
import styles from './starttemplate.module.css';
import Logo from "../../1atoms/logo/Logo";


export default function StartTemplate(props) {

  return (
    
    <main className={styles.background}> 
    <svg className={styles.animatedWave} viewBox="0 0 1440 138" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 69V138H1440H2880H4320V68.875C4235.63 68.875 4125.59 53.9516 4007.95 37.998C3872.86 19.6783 3727.75 0 3600 0C3472.04 0 3326.32 19.7775 3190.9 38.1556C3073.38 54.1048 2963.63 69 2880 69V68.875C2795.63 68.875 2685.59 53.9516 2567.95 37.998C2432.86 19.6783 2287.75 0 2160 0C2032.04 0 1886.32 19.7775 1750.9 38.1556C1633.38 54.1048 1523.63 69 1440 69V68.875C1355.63 68.875 1245.59 53.9516 1127.95 37.998C992.857 19.6783 847.75 0 720 0C592.044 0 446.318 19.7775 310.902 38.1556C193.384 54.1048 83.6318 69 0 69Z" fill="#0E3896"></path></svg>
      <section className={styles.container}>
        <NavLink to="../"><Logo state="fullWhiteLogo" size="medium"/></NavLink>
        <article className={styles.panel}>
          <h1 className={styles.title}>{props.title}</h1>
          <div className={styles.formPanelContainer}>
            {props.children}
          </div>
        </article>
      </section>
   </main>

  ); 
}
