import React, {useState} from 'react';

import { useForm } from 'react-hook-form';

// components
import Modal from '../../2molecules/modal/Modal';
import Button from '../../2molecules/button/Button';

// Firebase
import { doc, deleteDoc, query, collection, where, updateDoc, arrayRemove, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { getAuth} from "firebase/auth";

export default function ModalDeleteStudent({student}) {

    const storage = getStorage();
    const auth = getAuth();
    const { handleSubmit } = useForm();

    const [loading, setLoading] = useState('');

    // Update Information
    const onSubmit = (async () => {
        setLoading('loading');
        // Delete Image from Storage
         const studentStorageRef = ref(storage, `ZrewvPrq457M6f8AVI0T/students/${student.id}/${student.data.imgname}`);
  
        deleteObject(studentStorageRef).then(() => {
          console.log('success'); 
        }).catch((error) => {
            console.log(error); 
        });

        // Delete Student Reference from Teachers Group Doc
        const q = query(collection(db, `teachers/${auth.currentUser.uid}/groups/`), where("students", "array-contains", student.id));
        const querySnapshot = await getDocs(q);
        
        querySnapshot.docs.map((doc) => 
          updateDoc(doc.ref, { students: arrayRemove(student.id) })
        )

        // Delete Student Document
        deleteDoc(doc(db, "students", student.id));
        setLoading('');
        window.deleteStudentModal.close();
      })

  return (
    <Modal 
    title="Remove Student" 
    modalid='deleteStudentModal'
    button={<Button type={'primary'} click={ handleSubmit(onSubmit) } state={loading}>Remove</Button>} 
    >
    <p>This action will completely remove <strong>{student && `${student.data.namefirst} ${student.data.namelast}`} </strong> from application. Are you sure you want to continue?</p>
    </Modal>
  )
}
