import React, {useState, useEffect} from 'react';

import { useForm } from 'react-hook-form';

// components
import Modal from '../../2molecules/modal/Modal';
import Button from '../../2molecules/button/Button';

// Firebase
import { db } from "../../../firebase/firebase";
import { updateDoc, onSnapshot, doc } from "firebase/firestore";
import UpdateImageStudent from '../updateImageStudent/UpdateImageStudent';


export default function ModalEditStudent({student}) {

    const [loading, setLoading] = useState('');
   

    const { register, handleSubmit: handleEditStudent, formState: {errors}, setValue} = useForm();

    // Set Existing Information In Form
    useEffect(() => {
        if (student) {
            const studentPath = doc(db, `students/${student.id}`);
            const unsub = onSnapshot(studentPath, (doc) => {
            if (doc.exists) {
                const docData = doc.data();
                
                [
                {name: 'namefirst', value: docData.namefirst},
                {name: 'namelast', value: docData.namelast},
                ].forEach(({name, value}) => setValue(name, value))
            }

            });
            return unsub; 
        }  
    }, [student]);

    // Update Information
    const onSubmit = (async (values) => {
      
        setLoading('loading');
        try {
          const studentPath = new doc(db, `students/${student.id}`);
          const formSignUpData = {...values};
          await updateDoc(studentPath, formSignUpData);  
          
        } catch(error) {
          console.log(error);
        }
        setLoading('');
        window.editStudentModal.close();
      })


  return (
    <Modal 
    title="Edit Student" 
    modalid='editStudentModal'
    button={<Button type2={'button'} type={'primary'} click={ handleEditStudent(onSubmit) } state={loading}>Save</Button>} 
    >
    {student && <UpdateImageStudent studentid={student.id} /> }

        <form className="panelForm" autoComplete={"off"}>
            <div className="formContainer">
              <label>First Name:</label>
              <input 
               
                type='text' 
                name='namefirst'
                {...register('namefirst', { required: true })} 
              />
              {errors.namefirst ? <div className="error">Must enter first name.</div> :
              null }
            </div>
            <div className="formContainer">
              <label>Last Name:</label>
              <input 
               
                type='text' 
                name='namelast'
                {...register('namelast', { required: true })} 
              />
              {errors.namelast ? <div className="error">Must enter last name.</div> :
              null }
            </div>
        </form>
    </Modal>
  )
}
