import React, {useState, useEffect} from 'react';
import styles from './panelrewards.module.css';

import { useForm } from 'react-hook-form';

// Context
import { useFormData } from "../../../context";

// Firebase
import { doc, collection, increment, updateDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { getAuth } from "firebase/auth";

// Components
import Icons from '../../1atoms/icons/Icons';
import Button from '../../2molecules/button/Button';

export default function PanelReward({state}) {

  const auth = getAuth();

    const { setFormValues, data } = useFormData();
    const { register, handleSubmit: handleSubmit2, formState: {errors}, reset} = useForm();

    // const [showselected, setShowSelected] = useState();

    // useEffect(() => {
    //   const arrayData = Object.entries(data);

    //   arrayData.map((student) =>
    //       {if (student[0]!=='done'){
    //         if(student[1]===true) {
    //           let studentid = student[0];
    //           setShowSelected(showselected + ' ' + studentid + ' ') 
    //         }
    //       }}
    //     )
    // }, [data])
    
    
    const onSubmit = (async (values) => {
      const selectedStudents = data;
      const panelSelection = values;
      
        const arrayStudents = Object.entries(selectedStudents);
    
        arrayStudents.map(async (student) =>
          {if (student[0]!=='done'){
            if(student[1]===true) {
              let studentid = student[0];
              let studentPath = new doc(db, `students/${studentid}`);
              await updateDoc(studentPath, {
                points: increment(panelSelection.pointamount),
                pointscomment: panelSelection.comments,
                pointsdate: serverTimestamp(),
                pointsteacher: auth.currentUser.displayName,
              });

              let studentPointsPath = doc(collection(db, `students/${studentid}/points`)); 
              const formPointInfo = {...values};
              formPointInfo.date = serverTimestamp();
              formPointInfo.teacherid = auth.currentUser.uid;
              formPointInfo.teachername = auth.currentUser.displayName;

              setDoc(studentPointsPath, formPointInfo);
            }
          }}
        )
      
      setFormValues({done: true})
      setSelected();
      
      reset();
    });
    
     // Student Card Selection
     const [isSelected, setSelected] = useState();
    
     function checkClick(el) {
      let thing = el.target.id;
        setSelected(thing);
      }



  return (
  
    <div className={styles.container}>
    {/* <div><p>{showselected}</p></div> */}
      <form className="panelForm" autoComplete="off">
        <div className="formContainer">
            <label htmlFor="1" className={`${isSelected==1 ? styles.selected : null} radioButton`}  >
              <Icons over size="large" icon='Check'/>
              <input
                {...register('pointamount', { required: true })}
                type="radio"
                
                value={1}
                className={`form-check-input`}
                
                onClick={checkClick} 
                id="1"
              />{' '}
              1 Point
            </label>

            <label htmlFor="2" className={`${isSelected==2 ? styles.selected : null} radioButton`}>
              <Icons over size="large" icon='Check'/>
              <input
                {...register('pointamount', { required: true })}
                type="radio"
                name="pointamount"
                value={2}
                className={`form-check-input`}
                onClick={checkClick} 
                id="2"
              />{' '}
              2 Points
            </label>

            <label htmlFor="3" className={`${isSelected==3 ? styles.selected : null} radioButton`}>
            <Icons over size="large" icon='Check'/>
              <input
                {...register('pointamount', { required: true })}
                type="radio"
                name="pointamount"
                value={3}
                onClick={checkClick} 
                className={`form-check-input`}
                id="3"
              />{' '}
               3 Points
            </label>
          {errors.pointamount && <div className="error">Must have a point value.</div>}
        </div>

        <div className="formContainer">
          <label><strong>Comments</strong></label>
          <textarea
          type='text'
          {...register('comments')}
          />
        </div>

        <input
          type='hidden'
          {...register('done', {value: true})}
        />
 
      </form>
      
      
      <div className={styles.buttonContainer}>
        <Button state={state} click={handleSubmit2(onSubmit)}>Reward</Button>
      </div>
    </div>
    
  )
}
