import React from 'react';
import styles from './feed.module.css';


// Components
import Header from '../../3organisms/header/Header';
import MainPanel from '../../4templates/mainPanel/MainPanel';
import ListFeed from '../../3organisms/listfeed/ListFeed';
import PanelFeed from '../../3organisms/panelfeed/PanelFeed';

// Context
import TeacherProvider from "../../../context/teacher";



export default function FeedPage() {

    document.title = 'school feed';

  return (
   
    <TeacherProvider> 
        <Header type='Feed'/>
        <div className={styles.section}> 
          <MainPanel type='Feed'
            cards={<ListFeed/>} 
            panel={<PanelFeed/>}
          />
        </div>
      </TeacherProvider> 
  
  );
}