import React, {useState, useEffect} from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// components
import Modal from '../../2molecules/modal/Modal';
import Button from '../../2molecules/button/Button';

// Firebase
import { doc, deleteDoc, query, collection, where, updateDoc, arrayRemove, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { getAuth} from "firebase/auth";

export default function ModalDeleteGroup({group}) {

    const storage = getStorage();
    const auth = getAuth();
    const { handleSubmit } = useForm();
    const navigate = useNavigate();

    const [groupdata, setGroupData] = useState('');
    const [loading, setLoading] = useState('');

    // Set Existing Group Information
    useEffect(() => {
      if (group) {
          const groupPath = doc(db, `teachers/${auth.currentUser.uid}/groups/${group.id}`);
          const unsub = onSnapshot(groupPath, (doc) => {
          if (doc.exists) {
              setGroupData(doc.data());
      
          }

          });
          return unsub; 
      }  
  }, [group]);

  

    // Update Information
    const onSubmit = (async () => {
        setLoading('loading');
        // Delete Image from Storage
         const groupStorageRef = ref(storage, `ZrewvPrq457M6f8AVI0T/teachers/${auth.currentUser.uid}/groups/${group.id}/${groupdata.imgname}`);
  
        deleteObject(groupStorageRef).then(() => {
          console.log('success'); 
        }).catch((error) => {
            console.log(error); 
        });

        // Delete Student Reference from Teachers Group Doc
        const q = query(collection(db, `students`), where("group", "array-contains", group.id));
        const querySnapshot = await getDocs(q);
        
        querySnapshot.docs.map((doc) => 
          updateDoc(doc.ref, { group: arrayRemove(group.id) })
          
        )

        // Delete Student Document
        deleteDoc(doc(db, `teachers/${auth.currentUser.uid}/groups/${group.id}`));
        setLoading('');
        window.deleteGroupModal.close();
        navigate('/students');

      })

  return (
    <Modal 
    title="Delete Group" 
    modalid='deleteGroupModal'
    button={<Button type={'primary'} click={ handleSubmit(onSubmit) } state={loading}>Delete</Button>} 
    >
    <p>This action will completely delete this group from the application. Are you sure you want to continue?</p>
    </Modal>
  )
}
