import React, {useState, useEffect} from 'react'
import styles from './panelstore.module.css';

import { useForm } from "react-hook-form";

// Context
import { useFormData } from "../../../context";

// Firebase
import { db } from "../../../firebase/firebase";
import { doc, collection, increment, updateDoc, setDoc, serverTimestamp, onSnapshot, query } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Components
import Button from '../../2molecules/button/Button'
import Icons from '../../1atoms/icons/Icons';
import ModalAddItem from '../modalAddItem/modalAddItem';
import CardItem from '../../2molecules/carditem/cardItem';
import List from '../../2molecules/list/List';
import ButtonOptions2 from '../../2molecules/buttonoptions/ButtonOptions2';
import ButtonOptionItem from '../../2molecules/buttonoptionitem/ButtonOptionItem';
import ModalDeleteItem from '../modalDeleteItem/ModalDeleteItem';
import ModalEditItem from '../modalEditItem/ModalEditItem';


export default function PanelStore({state}) {

  const auth = getAuth();

  const { setFormValues, data } = useFormData();
  const { register, handleSubmit: handleSubmit3, reset} = useForm();

  



// List teacher items
const [update, setUpdate] = useState('')
const [loading, setSetLoading] = useState(false);
const [error, setError] = useState('');
const [length, setLength] = useState(0);
const [teacherItems, setTeacherItems] = useState('');

useEffect(() => {
  setSetLoading(true)
  const q = query(collection(db, `schools/ZrewvPrq457M6f8AVI0T/stores/teachers/${auth.currentUser.uid}`));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
  const items = [];
  querySnapshot.forEach((doc) => {
    if (doc.exists) {
        items.push({data: doc.data(), id: doc.id});
        
        setTeacherItems(items);
        setTimeout(() => {
          setSetLoading(false)
        }, 500);
        
    } else if (items.length===0) { 
      setSetLoading(false)
    } else {
      setError('Error loading items')
      setSetLoading(false)
    }
  });
  });

  setSetLoading(false)
  return unsubscribe;
  
}, [update]);

useEffect(() => {
  if (teacherItems) {
  setLength(teacherItems.length);
  
  } else {
    setLength(0);
  }
}, [teacherItems, update]);


// Sort by label 
if (teacherItems) {
  teacherItems.sort(function(a, b){return a.data.label.localeCompare(b.data.label)});
}



  // Make Purchase
  const onSubmit = (async (values) => {
  const selectedStudents = data;
  const panelSelection = values;
 

  let selected;
 
  teacherItems.forEach(item => {
    if (item.id === panelSelection.item) {
      selected = item.data;
       
    }
  })

  

 
  
    const arrayStudents = Object.entries(selectedStudents);

    arrayStudents.map(async (student) =>
      {if (student[0]!=='done'){
        if(student[1]===true) {
          let studentid = student[0];
          let studentPath = new doc(db, `students/${studentid}`);
          await updateDoc(studentPath, {
            points: increment(`-${selected.cost}`),
            itemlabel: selected.label,
            itemcost: selected.cost,
            itemdate: serverTimestamp(),
            itempurchaseteacher: auth.currentUser.displayName,
          });

          let studentPurchasesPath = doc(collection(db, `students/${studentid}/purchases`)); 
          const formStorePurchase = {...values};
          formStorePurchase.date = serverTimestamp();
          formStorePurchase.teacherid = auth.currentUser.uid;
          formStorePurchase.teachername = auth.currentUser.displayName;
          formStorePurchase.itemlabel = selected.label;
          formStorePurchase.itemcost = selected.cost;

          setDoc(studentPurchasesPath, formStorePurchase);
        }
      }}
    )
  
  setFormValues({done: true})
  
  reset();
});

// Item Options
const [setCardOptions, setIsCardOptions] = useState('');
const [editItemID, setEditItemID] = useState('');
const [deleteItemID, setDeleteItemID] = useState('');

function optionsItem(item) {
  
  let itemid = item.id;
  const itemOptions = 'optionsItem' + itemid;
  const options = document.getElementById(itemOptions);
  options.toggleAttribute('open');
  setIsCardOptions(options);
}

// Delete Item
function deleteItem(item) {
  setCardOptions.removeAttribute('open');
  // setUpdate(student.id);
  window.deleteItemModal.showModal();
  setDeleteItemID(item)
}

// Edit Item
function editItem(item) {
  setCardOptions.removeAttribute('open');
  // setUpdate(student.id);
  window.editItemModal.showModal();
  setEditItemID(item)
}


  return (
    <div className={styles.container}>
    <ModalAddItem />
    <ModalDeleteItem item={deleteItemID}/>
    <ModalEditItem item={editItemID}/>
    
    

    <Button purpose="create" type="icon" click={() => window.addItemModal.showModal()}>
      <Icons type='utility' icon='plus' size='small'/>
    </Button>


    
    
    <form className={`panelForm ${styles.itemList}`} autoComplete="off">
      <List loading={loading} length={length} error={error} none={'No items'}>
      {length && teacherItems.map((item, i) => (
      
        
        
        <CardItem 
          key={i} 
          checkbox={<input className={styles.itemCheckBox} type="radio" id={item.id} {...register('item')} value={item.id}  key={item.id}/>}
          image={item.data.itemimg}
          label={item.data.label}
          cost={item.data.cost}
          options={
              <>
                <Button id={'button' + item.id} type2={'button'} click={() => optionsItem(item)} type="icon" class={styles.options} >
                  <Icons type='utility' icon='ellipsis-vertical' size='small'/> 
                </Button>

                <ButtonOptions2 type={'optionsItem'} id={item.id}  >
                  <ButtonOptionItem click={() => editItem(item)} icon={'pencil'}>Edit Item</ButtonOptionItem>
                  <ButtonOptionItem click={()=> deleteItem(item)} icon={'xmark'}>Delete Item</ButtonOptionItem>
                </ButtonOptions2>
              </>
            }
          />
       
      ))}
      </List>
    </form>

    

    
    
    <div className={styles.buttonContainer}>
      <Button state={state} click={handleSubmit3(onSubmit)} >Purchase</Button>
    </div>
    </div>  
  )
}
