
import './index.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

// Routes
import PirvateRoute from './routes/PirvateRoute';

// Walkthrough Redux code for Storybook
// import store from './lib/store';
// import { Provider } from 'react-redux';

// Context for Actions
// import FormProvider from "./context";

import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';

// Pages
// Marketing Page
import MarketMainPage from './components/5pages/MarketMain/MarketMainPage';
import MarketFeaturesPage from './components/5pages/MarketFeatures/MarketFeaturesPage';

// Start Pages
import SignInPage from './components/5pages/StartSignIn/SignInPage';
import PasswordResetPage from './components/5pages/StartPasswordReset/PasswordResetPage';
import SignUpPage from './components/5pages/StartSignUp/SignUpPage';

// Application Pages
import TeacherPage from './components/5pages/MainTeacher/TeacherPage';
import FeedPage from './components/5pages/Feed/FeedPage';
import StudentPage from './components/5pages/MainStudents/StudentPage';
import StudentGroupPage from './components/5pages/MainStudentsGroups/StudentGroupPage';
import ProfileTeacherPage from './components/5pages/ProfileTeacher/ProfileTeacherPage';


// Admin Page
import AdminMainPage from './components/5pages/AdminMain/AdminMainPage';
import UpdatePage from './components/5pages/StartUpdates/UpdatePage';
import EarlyAccesssRoute from './routes/EarlyAccessRoute';


// System Pages
// Not Found, Error

function App() {

 

  return (
<HubspotProvider>
    <Router>

        <Routes>
            <Route path="/" element={<MarketMainPage/>} />
            <Route path="/features" element={<MarketFeaturesPage/>} />

            <Route path="/signin" element={<SignInPage/>} />
            <Route path="/password-reset" element={<PasswordResetPage/>} />
            <Route path="/stayupdated" element={<UpdatePage/>} /> 
            <Route path="/tester" element={<SignUpPage/>} />

            
            <Route path='/signup' element={<EarlyAccesssRoute/>}>
              <Route path="/signup" element={<SignUpPage/>} />
            </Route>

            <Route path='/teachers' element={<PirvateRoute/>}>
              <Route path="/teachers" element={<TeacherPage/>} />
            </Route>

            <Route path='/teacher-profile' element={<PirvateRoute/>}>
              <Route path="/teacher-profile" element={<ProfileTeacherPage/>} />
            </Route>

            <Route path='/admin' element={<PirvateRoute/>}>
              <Route path="/admin" element={<AdminMainPage/>} />
            </Route>
            
            
            <Route path='/student-groups/:id' element={<PirvateRoute/>}>
              <Route path="/student-groups/:id" element={<StudentGroupPage/>} />
            </Route>


            <Route path='/students' element={<PirvateRoute/>}>
              <Route path="/students" element={<StudentPage/>} />
            </Route>

            <Route path='/feed' element={<PirvateRoute/>}>
              <Route path="/feed" element={<FeedPage/>} />
            </Route>
        </Routes>  
      </Router>
      </HubspotProvider>


  );
}

export default App;
