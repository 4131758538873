import React, {useEffect, useState} from "react";
import styles from './typeselection.module.css';

import { NavLink } from "react-router-dom";

// Components
import Button from "../../2molecules/button/Button";
import Icons from "../../1atoms/icons/Icons";
import ModalAddGroup from "../modalAddGroup/ModalAddGroup";
import TypeItem from "../../2molecules/typeitem/TypeItem";
import TypeItemno from "../../2molecules/typeitem/TypeItemno";


export default function TypeSelection({groups, dragdrop}) {

  const [groupList, setGroupList] = useState('')
  
  
    
  

useEffect(() => {
  if (groups.length!==0) {
    if (dragdrop!==false) {
      setGroupList(groups.map((group, i) => ( <TypeItem group={group} key={i} />)));
    } else {
      setGroupList(groups.map((group, i) => ( <TypeItemno group={group} key={i} />)));
    }
  } else {
      setGroupList(<p className="details"><br/>Click the <strong>Plus Icon</strong><br/> to add a group.</p> );
  }

}, [groups, dragdrop])

  

  return (
    <>
    <ModalAddGroup />

    <aside className={styles.selectionType}>
        <NavLink to="/feed" className={({ isActive }) => isActive ? styles.active : undefined }>
          <img src="https://schoolwio-92950.web.app/images/School-Feed.png" alt=""/>
          <p>School Feed</p>
        </NavLink>

        <div className={styles.containerLabel}>
        <h2>ALL</h2>
       </div>
       
       <NavLink to="/students" className={({ isActive }) => isActive ? styles.active : undefined }>
          <img src="https://schoolwio-92950.web.app/images/Students-All.png" alt=""/>
          <p>Students</p>
        </NavLink>
       <NavLink to="/teachers" className={({ isActive }) => isActive ? styles.active : undefined }>
          <img src="https://schoolwio-92950.web.app/images/Teachers-All.png" alt=""/>
          <p>Teachers</p>
        </NavLink>
        
        <div className={styles.containerLabel}>
          <h2>GROUPS</h2>
          <Button type='icon' size='small' purpose='create' class={styles.typeCreate} click={() => window.createGroupModal.showModal()}>
            <Icons size='small' type='utility' icon='plus' />
          </Button>
        </div>
       
      {groupList}
       
       
    </aside>
    </>
  );
}
