import { useState, createContext, useContext } from "react";

import { useForm } from "react-hook-form";

export const FormContext = createContext();

export default function FormProvider({ children }) {

  const { reset } = useForm();


  const [data, setData] = useState({done: false});

  const setFormValues = (values) => {

    
    setData(
      (prevValues) => ({
      ...prevValues,
      ...values,
    })
    );
    // if (data.done===true) {
    //   // setData(data.done === false)
    //   reset();
    // }
  };


  return (
    <FormContext.Provider value={{ data, setFormValues }}>
      {children}
    </FormContext.Provider>
  );
}

export const useFormData = () => useContext(FormContext);