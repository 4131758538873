import React, {useState} from 'react';

import { useForm } from 'react-hook-form';

// components
import Modal from '../../2molecules/modal/Modal';
import Button from '../../2molecules/button/Button';

// Firebase
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { getAuth} from "firebase/auth";

export default function ModalDeleteItem({item}) {

    const storage = getStorage();
    const auth = getAuth();
    const { handleSubmit } = useForm();

    const [loading, setLoading] = useState('');

    // Update Information
    const onSubmit = (async () => {
        setLoading('loading');
        // Delete Image from Storage
         const itemStorageRef = ref(storage, `ZrewvPrq457M6f8AVI0T/stores/${auth.currentUser.uid}/${item.data.itemimgname}`);
  
        deleteObject(itemStorageRef).then(() => {
          console.log('success'); 
        }).catch((error) => {
            console.log(error); 
        });


        // Delete Student Document
        deleteDoc(doc(db, `schools/ZrewvPrq457M6f8AVI0T/stores/teachers/${auth.currentUser.uid}/${item.id}`));
        setLoading('');
        window.deleteItemModal.close();
      })

  return (
    <Modal 
    title="Delete Item" 
    modalid='deleteItemModal'
    button={<Button type={'primary'} click={ handleSubmit(onSubmit) } state={loading}>Delete</Button>} 
    >
    <p>This action will completely delete <strong>{item && `${item.data.label} `}</strong> from application. Are you sure you want to continue?</p>
    </Modal>
  )
}
