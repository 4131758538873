import React from "react";
import styles from './icons.module.css';
import solid from './solid.svg';


export default function Icons({over, icon, size, type}) {


  return (
    <span className={`
        ${styles.icon}
        ${over===true && styles.over}
        
        ${size==='small' ? styles.small : 
          size==='medium' ? styles.medium : 
          size==='large' ? styles.large : 
          styles.small 
        }
    `}>

        {/* Menu */}
        {icon === "Reward" && (
          <svg viewBox="0 0 110.37 110.37">
            <path d="M86.29,51.58l-12.75,12.43,3.02,17.55c.54,3.17-2.8,5.54-5.6,4.07l-15.77-8.29-15.77,8.29c-2.8,1.48-6.14-.89-5.6-4.07l3.02-17.55-12.75-12.43c-2.29-2.23-1.03-6.13,2.14-6.59l17.63-2.57,7.88-15.97c1.42-2.88,5.51-2.85,6.92,0l7.88,15.97,17.63,2.57c3.16,.46,4.43,4.36,2.14,6.59Zm-6.83-1.42l-16.77-2.44-7.5-15.2-7.5,15.2-16.77,2.44,12.14,11.82-2.86,16.7,15-7.88,15,7.88-2.86-16.7,12.14-11.82Z"/>
          </svg>
        )}
       
        {icon === "Store" && (
          <svg viewBox="0 0 110.37 110.37">
            <path d="M88.41,36.36l-5.23,24.04c-.28,1.28-1.41,2.18-2.71,2.18H44.36l1.09,5.55h32.61c1.77,0,3.09,1.63,2.71,3.36l-.12,.55c1.4,1.35,2.27,3.24,2.27,5.33,0,4.09-3.31,7.4-7.4,7.4s-7.4-3.31-7.4-7.4v-.02c0-1.34,.36-2.6,.99-3.68h-16.78c.63,1.08,.99,2.35,.99,3.69h0c0,4.09-3.31,7.41-7.4,7.41s-7.4-3.31-7.4-7.4c0-1.99,.79-3.79,2.06-5.12l-8.04-41.11h-9.27c-.77,0-1.39-.62-1.39-1.39v-2.77c0-.77,.62-1.39,1.39-1.39h11.56c1.33,0,2.47,.94,2.72,2.24l1.01,5.16h47.13c1.77,0,3.09,1.63,2.71,3.36Zm-6.15,2.18H39.66l3.62,18.49h34.96l4.02-18.49Zm-33.55,38.84c0-1.53-1.24-2.77-2.77-2.77s-2.77,1.24-2.77,2.77,1.24,2.77,2.77,2.77,2.77-1.24,2.77-2.77Zm29.59,0c0-1.53-1.24-2.77-2.77-2.77s-2.77,1.24-2.77,2.77,1.24,2.77,2.77,2.77,2.77-1.24,2.77-2.77Z"/>
          </svg>
        )}

        {icon === "SEL" && (
          <svg viewBox="0 0 110.37 110.37">
            <path d="M82.13,58.92l-22.19,22.6c-1.26,1.29-2.95,2-4.76,2s-3.49-.72-4.76-2.01l-22.19-22.6c-6.78-6.92-8.17-19.97,1.34-27.98,7.31-6.16,18.61-5.22,25.6,1.9,6.99-7.12,18.29-8.04,25.6-1.9,9.52,8.02,8.11,21.07,1.34,27.99Zm-5.25-23.35c-4.9-4.12-12.48-3.53-17.27,1.34l-4.43,4.52-4.43-4.52c-4.76-4.84-12.35-5.46-17.27-1.33-6.48,5.45-5.54,14.36-.92,19.06l22.19,22.6c.3,.3,.56,.3,.86,0l22.19-22.6c4.58-4.67,5.54-13.62-.92-19.07Z"/>
            <path d="M42.81,55.08c-.51-.51-.51-1.32,0-1.83l1.83-1.83c.51-.51,1.32-.51,1.83,0l5.67,5.67,12.14-12.14c.51-.51,1.32-.51,1.83,0l1.83,1.83c.51,.51,.51,1.32,0,1.83l-14.88,14.88c-.51,.51-1.32,.51-1.83,0l-8.41-8.41Z"/>
          </svg> 
        )}

        {icon === "Information" && (
          <svg viewBox="0 0 110.37 110.37">
          <path d="M87.38,54.95c0,17.5-14.18,31.68-31.68,31.68s-31.68-14.18-31.68-31.68,14.18-31.68,31.68-31.68,31.68,14.18,31.68,31.68Zm-5.94,0c0-14.19-11.54-25.74-25.74-25.74s-25.74,11.55-25.74,25.74,11.54,25.74,25.74,25.74,25.74-11.55,25.74-25.74Zm-17.82,12.87c0,1.64-1.33,2.97-2.97,2.97h-9.9c-1.63,0-2.97-1.34-2.97-2.97s1.34-2.97,2.97-2.97h1.98v-7.92h-.99c-1.63,0-2.97-1.34-2.97-2.97s1.34-2.97,2.97-2.97h3.96c1.65,0,2.97,1.34,2.97,2.97v10.89h1.98c1.65,0,2.97,1.33,2.97,2.97Zm-11.88-24.75c0-2.19,1.77-3.96,3.96-3.96s3.96,1.77,3.96,3.96-1.77,3.96-3.96,3.96-3.96-1.77-3.96-3.96Z"/>
          </svg> 
        )}

        {/* SEL */}
        {icon === "Stressed" && (
          <svg viewBox="0 0 110.37 110.37">
            <path d="M94.41,55.19c0,21.67-17.56,39.23-39.23,39.23S15.96,76.86,15.96,55.19,33.52,15.96,55.19,15.96s39.23,17.56,39.23,39.23Zm-7.59,0c0-17.45-14.19-31.63-31.63-31.63s-31.63,14.19-31.63,31.63,14.19,31.63,31.63,31.63,31.63-14.19,31.63-31.63Zm-8.86,7.59v5.06c0,4.19-3.4,7.59-7.59,7.59h-30.37c-4.19,0-7.59-3.4-7.59-7.59v-5.06c0-4.19,3.4-7.59,7.59-7.59h30.37c4.19,0,7.59,3.4,7.59,7.59Zm-40.49-15.18c0-2.8,2.26-5.06,5.06-5.06s5.06,2.26,5.06,5.06-2.26,5.06-5.06,5.06-5.06-2.26-5.06-5.06Zm6.33,12.65h-3.8c-1.39,0-2.53,1.14-2.53,2.53v1.27h6.33v-3.8Zm0,6.33h-6.33v1.27c0,1.39,1.14,2.53,2.53,2.53h3.8v-3.8Zm10.12-6.33h-7.59v3.8h7.59v-3.8Zm0,6.33h-7.59v3.8h7.59v-3.8Zm10.12-6.33h-7.59v3.8h7.59v-3.8Zm0,6.33h-7.59v3.8h7.59v-3.8Zm-1.27-18.98c0-2.8,2.26-5.06,5.06-5.06s5.06,2.26,5.06,5.06-2.26,5.06-5.06,5.06-5.06-2.26-5.06-5.06Zm10.12,15.18c0-1.39-1.14-2.53-2.53-2.53h-3.8v3.8h6.33v-1.27Zm0,3.8h-6.33v3.8h3.8c1.39,0,2.53-1.14,2.53-2.53v-1.27Z"/>
          </svg>
        )}

        {icon === "Calm" && (
          <svg viewBox="0 0 110.37 110.37">
            <path d="M94.41,55.19c0,21.67-17.56,39.23-39.23,39.23S15.96,76.86,15.96,55.19,33.52,15.96,55.19,15.96s39.23,17.56,39.23,39.23Zm-7.59,0c0-17.45-14.19-31.63-31.63-31.63s-31.63,14.19-31.63,31.63,14.19,31.63,31.63,31.63,31.63-14.19,31.63-31.63Zm-12.13,10.11c-1.27,7.45-11.28,12.65-19.5,12.65s-18.25-5.2-19.5-12.65c-.27-1.57,1.23-2.91,2.8-2.42,4.11,1.31,10.19,2.07,16.7,2.07s12.61-.76,16.7-2.07c1.58-.49,3.05,.87,2.8,2.42Zm-37.22-17.7c0-2.8,2.26-5.06,5.06-5.06s5.06,2.26,5.06,5.06-2.26,5.06-5.06,5.06-5.06-2.26-5.06-5.06Zm25.31,0c0-2.8,2.26-5.06,5.06-5.06s5.06,2.26,5.06,5.06-2.26,5.06-5.06,5.06-5.06-2.26-5.06-5.06Z"/>
          </svg>
        )}

        {icon === "Angry" && (
          <svg viewBox="0 0 110.37 110.37">
            <path d="M94.41,55.19c0,21.67-17.56,39.23-39.23,39.23S15.96,76.86,15.96,55.19,33.52,15.96,55.19,15.96s39.23,17.56,39.23,39.23Zm-7.59,0c0-17.45-14.19-31.63-31.63-31.63s-31.63,14.19-31.63,31.63,14.19,31.63,31.63,31.63,31.63-14.19,31.63-31.63Zm-44.29-2.53c-2.8,0-5.06-2.26-5.06-5.06s2.26-5.06,5.06-5.06,5.06,2.26,5.06,5.06-2.26,5.06-5.06,5.06Zm27.81,17.27c.32,1.87-1.44,3.42-3.24,2.86-4.94-1.52-9.4-2.42-11.91-2.42s-6.98,.9-11.91,2.42c-1.82,.54-3.56-1-3.24-2.86,1.11-6.31,9.52-9.68,15.15-9.68s14.05,3.37,15.15,9.68Zm2.56-22.33c0,2.8-2.26,5.06-5.06,5.06s-5.06-2.26-5.06-5.06,2.26-5.06,5.06-5.06,5.06,2.26,5.06,5.06Z"/>
          </svg>
        )}

        {icon === "Nocheck" && (
          <svg viewBox="0 0 100 100">
          <path d="M12.2,50c0-20.9,16.9-37.8,37.8-37.8c20.9,0,37.8,16.9,37.8,37.8c0,20.9-16.9,37.8-37.8,37.8C29.1,87.8,12.2,70.9,12.2,50z
		 M50,19.3C33,19.3,19.3,33,19.3,50S33,80.7,50,80.7C67,80.7,80.7,67,80.7,50S67,19.3,50,19.3z M43,42.9c0,2.6-2.1,4.7-4.7,4.7
		c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7C40.8,38.2,43,40.3,43,42.9z M57.1,42.9c0-2.6,2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7
		s-2.1,4.7-4.7,4.7C59.3,47.6,57.1,45.5,57.1,42.9z"/>
          </svg>
        )}

        {/* View */}
        {icon === "Card" && (
          <svg viewBox="0 0 110.37 110.37">
          <path d="M53.15,29.8v20.31c0,1.68-1.36,3.05-3.05,3.05H25.73c-1.68,0-3.05-1.36-3.05-3.05V29.8c0-1.68,1.36-3.05,3.05-3.05h24.38c1.68,0,3.05,1.36,3.05,3.05Zm-27.42,27.42h24.38c1.68,0,3.05,1.36,3.05,3.05v20.31c0,1.68-1.36,3.05-3.05,3.05H25.73c-1.68,0-3.05-1.36-3.05-3.05v-20.31c0-1.68,1.36-3.05,3.05-3.05Zm58.91-30.47c1.68,0,3.05,1.36,3.05,3.05v20.31c0,1.68-1.36,3.05-3.05,3.05h-24.38c-1.68,0-3.05-1.36-3.05-3.05V29.8c0-1.68,1.36-3.05,3.05-3.05h24.38Zm-27.42,53.83v-20.31c0-1.68,1.36-3.05,3.05-3.05h24.38c1.68,0,3.05,1.36,3.05,3.05v20.31c0,1.68-1.36,3.05-3.05,3.05h-24.38c-1.68,0-3.05-1.36-3.05-3.05Z"/>
          </svg>
        )}

        {icon === "Table" && (
          <svg viewBox="0 0 110.37 110.37">
            <path d="M41.64,29.8v10.16c0,1.68-1.36,3.05-3.05,3.05h-12.86c-1.68,0-3.05-1.36-3.05-3.05v-10.16c0-1.68,1.36-3.05,3.05-3.05h12.86c1.68,0,3.05,1.36,3.05,3.05Zm0,30.47c0,1.68-1.36,3.05-3.05,3.05h-12.86c-1.68,0-3.05-1.36-3.05-3.05v-10.16c0-1.68,1.36-3.05,3.05-3.05h12.86c1.68,0,3.05,1.36,3.05,3.05v10.16Zm-15.91,7.11h12.86c1.68,0,3.05,1.36,3.05,3.05v10.16c0,1.68-1.36,3.05-3.05,3.05h-12.86c-1.68,0-3.05-1.36-3.05-3.05v-10.16c0-1.68,1.36-3.05,3.05-3.05ZM48.75,26.75h35.89c1.68,0,3.05,1.36,3.05,3.05v10.16c0,1.68-1.36,3.05-3.05,3.05H48.75c-1.68,0-3.05-1.36-3.05-3.05v-10.16c0-1.68,1.36-3.05,3.05-3.05Zm-3.05,33.52v-10.16c0-1.68,1.36-3.05,3.05-3.05h35.89c1.68,0,3.05,1.36,3.05,3.05v10.16c0,1.68-1.36,3.05-3.05,3.05H48.75c-1.68,0-3.05-1.36-3.05-3.05Zm0,20.31v-10.16c0-1.68,1.36-3.05,3.05-3.05h35.89c1.68,0,3.05,1.36,3.05,3.05v10.16c0,1.68-1.36,3.05-3.05,3.05H48.75c-1.68,0-3.05-1.36-3.05-3.05Z"/>
          </svg>
        )}

        {/* Action */}
        {icon === "Check" && (
          <svg viewBox="0 0 110.37 110.37">
          <path d="M41.78,55.15c-.55-.55-.55-1.43,0-1.98l1.98-1.98c.55-.55,1.43-.55,1.98,0l6.13,6.13,13.12-13.12c.55-.55,1.43-.55,1.98,0l1.98,1.98c.55,.55,.55,1.43,0,1.98l-16.09,16.09c-.55,.55-1.43,.55-1.98,0l-9.1-9.1Z"/>
            <line className={styles.checkSparkle} x1="100.72" y1="71.3" x2="79.24" y2="63.13"/>
            <line className={styles.checkSparkle} x1="81.22" y1="83.7" x2="72.14" y2="73.32"/>
            <line className={styles.checkSparkle} x1="70.1" y1="100.35" x2="63.1" y2="78.45"/>
            <line className={styles.checkSparkle} x1="48.25" y1="92.81" x2="50.69" y2="79.24"/>
            <line className={styles.checkSparkle} x1="28.29" y1="94.49" x2="41.06" y2="75.37"/>
            <line className={styles.checkSparkle} x1="20.54" y1="72.72" x2="32.67" y2="66.17"/>
            <line className={styles.checkSparkle} x1="6.83" y1="58.14" x2="29.73" y2="56.21"/>
            <line className={styles.checkSparkle} x1="18.99" y1="38.51" x2="31.67" y2="43.93"/>
            <line className={styles.checkSparkle} x1="21.84" y1="18.72" x2="37.65" y2="35.41"/>
            <line className={styles.checkSparkle} x1="44.79" y1="16.01" x2="48.45" y2="29.3"/>
            <line className={styles.checkSparkle} x1="62.01" y1="5.87" x2="58.85" y2="28.63"/>
            <line className={styles.checkSparkle} x1="78.45" y1="22.12" x2="70.33" y2="33.27"/>
            <line className={styles.checkSparkle} x1="97.12" y1="29.26" x2="77.35" y2="40.97"/>
            <line className={styles.checkSparkle} x1="94.65" y1="52.24" x2="80.87" y2="52.85"/>
          </svg>
        )}
        {type === "utility" && 
        
          <svg >
            <use xlinkHref={`${solid}#${icon}`}></use>
          </svg>
        }

    </span>
  );
}
