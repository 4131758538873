import React from 'react'
import styles from './panelheader.module.css';

// Components
import Icons from '../../1atoms/icons/Icons';

export default function PanelHeader({state}) {

    let style;

    switch (state) {
    case 'Reward':
        style = styles.reward;
        break;
    case 'Store':
        style = styles.store;
        break;
    case 'SEL':
        style = styles.sel;
    }

  return (
    <div className={`${styles.header} ${style}`}>
        <Icons size="medium" icon={state}/> 
        <p><strong>{state}</strong></p>
    </div>
    )
}
