import React from 'react';
import styles from './panelfeed.module.css';

import Icons from '../../1atoms/icons/Icons';
import Panel from '../../2molecules/panel/Panel';

export default function Feedside() {

  return (
    <>
    {/* <Panel>
    Announcements
    </Panel> */}

    {/* <section className={styles.listOffers}>
    <h2>Trainings</h2>
    <a href="" className={styles.offerLink}><p>Active Shooter</p> <p className="details">Due: Date</p></a>
    <a href="" className={styles.offerLink}><p>Finding Character Strength</p> <p className="details">Due: Date</p></a>
    </section> */}
      
        <section className={styles.listOffers}>
        <h2>Offers</h2>
          <a target="_blank" href="https://www.pbisrewards.com/conference/" className={styles.offerLink}>
            <article className={styles.offerContainer}>
              <img src="images/offer-1.png"/>
              <div className={styles.offerContent}>
                <h3>PBIScon 2023</h3>
                <p className="details">Learn more about our coming conference.</p>
              </div>
            </article>
          </a>

          <a target="_blank" href="https://www.cvent.com/c/calendar/d11b09fd-8814-42b5-8229-cad118ab88e1" className={styles.offerLink}>
            <article  className={styles.offerContainer}>
              <img src="images/offer-2.png"/>
              <div className={styles.offerContent}>
                <h3>ALICE Training</h3>
                <p className="details">Active Shooter Response Training & Personal Safety Skills</p>
              </div>
            </article>
          </a>

          <a target="_blank" href="https://www.pbisrewards.com/training/" className={styles.offerLink}>
            <article className={styles.offerContainer}>
              <img src="images/offer-3.png"/>
              <div className={styles.offerContent}>
                <h3>PBIS Training</h3>
                <p className="details">Learn how to reward points, hold events and manage your school-wide PBIS school.</p>
              </div>
            </article>
          </a>



        </section>
      

    </>
    
  );


}