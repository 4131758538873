import React from 'react';
import { NavLink } from "react-router-dom";

// Components
import MarketTemplate from '../../4templates/marketTemplate/MarketTemplate';
import styles from '../../4templates/marketTemplate/markettemplate.module.css';


export default function MarketMainPage() {

  document.title = 'a digital school for everyone';

  return (
    <MarketTemplate > 
      <section id="section-connect" className={`${styles.section}`}>
        
        <article className={`${styles.container} ${styles.medium} ${styles.flex}`}>
        <img alt="school teacher" className={`${styles.profileImg} ${styles.one}`} src="images/marketing-profile-1.png"/>
        <img alt="elemarty school student" className={`${styles.profileImg} ${styles.two}`} src="images/marketing-profile-2.png"/>
        <img alt="middle school student" className={`${styles.profileImg} ${styles.three}`} src="images/marketing-profile-3.png"/>
        <img alt="high school student" className={`${styles.profileImg} ${styles.four}`} src="images/marketing-profile-4.png"/>

          <div className={styles.flex300}>
            <img alt="schoolwio mobile application screenshot of SEL" src="images/marketing-mobile-app.png"/>
          </div>

          <div className={styles.flex900}>
            <h2><span>your</span> digital school</h2>
            <p>Group and interact with your students on<br/> mobile and desktop.</p>
            <NavLink to="../signup" className={`${styles.button} ${styles.orange} ${styles.large}`}>try today!</NavLink> 
          </div>
          <div className={`${styles.circleBack} ${styles.ltblue}`}></div>
          <div className={`${styles.circleBack} ${styles.orange}`}></div>
          <div className={`${styles.circleBack} ${styles.blue}`}></div>
          <div className={`${styles.circleBack} ${styles.green}`}></div>

        </article>
        
      </section>

      <section id="section-work-it-out" className={`${styles.section} ${styles.hidden} ${styles.overlap}`}>
      
      <img alt="deco" role="presentation" className={styles.curveImg} src="images/marketing-curve-layout.svg"/>
     
        <article className={`${styles.container} ${styles.center} ${styles.small}`}>
          <img alt="desktop mobile application illustration" src="images/marketing-desktop-mobile-2.svg"/>
        </article>
        <svg className={styles.animatedWave} viewBox="0 0 1440 138" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 69V138H1440H2880H4320V68.875C4235.63 68.875 4125.59 53.9516 4007.95 37.998C3872.86 19.6783 3727.75 0 3600 0C3472.04 0 3326.32 19.7775 3190.9 38.1556C3073.38 54.1048 2963.63 69 2880 69V68.875C2795.63 68.875 2685.59 53.9516 2567.95 37.998C2432.86 19.6783 2287.75 0 2160 0C2032.04 0 1886.32 19.7775 1750.9 38.1556C1633.38 54.1048 1523.63 69 1440 69V68.875C1355.63 68.875 1245.59 53.9516 1127.95 37.998C992.857 19.6783 847.75 0 720 0C592.044 0 446.318 19.7775 310.902 38.1556C193.384 54.1048 83.6318 69 0 69Z" fill="#0E3896"></path></svg>
        
        
      </section>

      <section className={styles.section}>
        <ul className={styles.whoFor}> 
          <li>Students</li>
          <li>Teachers</li>
          <li>Family</li>
          <li>School</li>
        </ul>

        <article id={styles.yourworkitoutapp} className={`${styles.container} ${styles.center} ${styles.medium}`}>

          <img alt="work it out acronym" className={styles.logoAcronym}src="images/marketing-logo-acronym.svg"/>
          <h2> <span>your</span> work it out <span>app</span> </h2>
          <p className={styles.columns}><strong>A student's well-being is dependent on many individuals inside and outside the school.</strong> Information and communication are in different formats and systems. Inconsistent methods and tracking make the student's progress slow. Whether you are using MTSS, RTI (Tiers 1-3), PBIS, Interventions, SEL, CASEL or some other variation, we are here to help communicate, securely share,  and track your student's progress.</p>

        </article>

    </section>


    <section id="section-work-it-out" className={`${styles.container} ${styles.howSection}`}>
    <article className={`${styles.containerFlip}`}>
      <div className={`${styles.flexOneThird} ${styles.flexOneSwitch}`}>
        <img alt="class outside with teacher" src="images/how-image-1.png"/>
      </div>
      <div className={`${styles.flexTwoThirds} ${styles.flexTwoThirdsSwitch}`}>
        <h2>communicate <span>better</span></h2>
        <p>Quickly communicate with school faculty, family members, and students.</p>
        <NavLink to="../signup" className={`${styles.button} ${styles.ltblue} ${styles.large}`}>learn more</NavLink> 
      </div>
    </article>

    <article className={`${styles.containerFlip}`}>
      <div className={`${styles.flexOneThird} `}>
        <img alt="students working together" src="images/how-image-2.png"/>
      </div>
      <div className={`${styles.flexTwoThirds} `}>
        <h2>social emotional <span>tracking</span></h2>
        <p>Keep track of students' emotional states and context school-wide.</p>
        <NavLink to="../signup" className={`${styles.button} ${styles.green} ${styles.large}`}>learn more</NavLink> 
      </div>
    </article>

    <article className={`${styles.containerFlip}`}>
      <div className={`${styles.flexOneThird} ${styles.flexOneSwitch}`}>
      <img alt="students going outside" src="images/how-image-3.png"/>
      </div>
      <div className={`${styles.flexTwoThirds} ${styles.flexTwoThirdsSwitch}`}>
        <h2>character <span>strengths</span></h2>
        <p>Give students goals based on what motivates them and track their progress.</p>
        <NavLink to="../signup" className={`${styles.button} ${styles.orange} ${styles.large}`}>learn more</NavLink> 
      </div>
    </article>

    <article className={`${styles.containerFlip}`}>
      <div className={`${styles.flexOneThird} `}>
      <img alt="students working on an assignment together" src="images/how-image-4.png"/>
      </div>
      <div className={`${styles.flexTwoThirds} `}>
        <h2>positive <span>climate</span></h2>
        <p>When the right systems are in place right things happen by default.</p>
        <NavLink to="../signup" className={`${styles.button} ${styles.blue} ${styles.large}`}>learn more</NavLink> 
      </div>
    </article>


    </section>

 

      <section id={styles.sectionalltogethernow} className={`${styles.section}`}>
     
        <article className={`${styles.container} ${styles.center}  ${styles.small}`}>
        
          <h2>
            <span className={styles.orange}> all</span>
            <span className={styles.green}> together</span>
            <span className={styles.ltblue}> now</span>
          </h2>

          <p><strong>Your school is more than a building.</strong> Everyday your students look to you for strength, energy and inspiration. This is a tool to help you manage what matters most your day.</p>  
          <NavLink to="../signup" className={`${styles.button} ${styles.orange} ${styles.large} ${styles.centerblock}`}>try today!</NavLink>
          
        </article>

        
        
        
        
      </section>

      <section id={styles.sectionplaytent} className={`${styles.section}`}>
        <img alt="deco" role="presentation" className={styles.curveImg} src="images/marketing-curve-white.svg"/>
        <img alt="students playing outside with parachute" className={styles.kidImg} src="images/marketing-back-play-together.png"/>
      </section>

    </MarketTemplate>
  )
}
