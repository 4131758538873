import React from 'react';
import { NavLink } from "react-router-dom";

// Components
import Icons from '../../1atoms/icons/Icons';
import MarketTemplate from '../../4templates/marketTemplate/MarketTemplate';
import styles from '../../4templates/marketTemplate/markettemplate.module.css';


export default function MarketFeaturesPage() {

  document.title = 'schoolwio | features';

  return (
    <MarketTemplate > 
      

      <section className={styles.section}>
        
        

        <article id="section-features" className={`${styles.container} ${styles.center} ${styles.small}`}>

          <h2>features</h2>
          <p><strong>What you need where you need it.</strong></p>
          <div className={styles.grid}>

            <div className={`${styles.flex} ${styles.center}  ${styles.flexDirection}`}>
              <div className={`${styles.circleIcon} ${styles.orange}`}>
                <img src="images/marketing-icon-points.svg"/>
                
              </div>
              <p>give student points</p>
            </div>

            <div className={`${styles.flex} ${styles.center} ${styles.flexDirection}`}>
              <div className={`${styles.circleIcon} ${styles.green}`}>
              <img src="images/marketing-icon-store.svg"/>
              </div>
              <p>manage store</p>
            </div>

            <div className={`${styles.flex} ${styles.center} ${styles.flexDirection}`}>
              <div className={`${styles.circleIcon} ${styles.ltblue}`}>
              <img src="images/marketing-icon-status.svg"/>
                </div>
              <p>student status</p>
            </div>

            <div className={`${styles.flex} ${styles.center}  ${styles.flexDirection}`}>
              <div className={`${styles.circleIcon} ${styles.green}`}>
                <img src="images/marketing-icon-message.svg"/>
              </div>
              <p>message everyone</p>
            </div>

            <div className={`${styles.flex} ${styles.center} ${styles.flexDirection}`}>
              <div className={`${styles.circleIcon} ${styles.ltblue}`}>
                <img src="images/marketing-icon-group.svg"/>
              </div>
              <p>create groups</p>
            </div>

            <div className={`${styles.flex} ${styles.center} ${styles.flexDirection}`}>
              <div className={`${styles.circleIcon} ${styles.orange}`}>
                <img src="images/marketing-icon-devices.svg"/>
              </div>
              <p>desktop and mobile app</p>
            </div>

          </div>
          {/* end of grid */}
          <NavLink to="../signup" className={`${styles.button} ${styles.green} ${styles.large} ${styles.centerblock}`}>try today!</NavLink>

        </article>
       
      </section>

      

    </MarketTemplate>
  )
}
