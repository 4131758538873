import React, {useEffect, useState} from 'react';
import styles from './typeitem.module.css';

import { NavLink } from "react-router-dom";

// Drag and Drop
import { useDrop } from 'react-dnd'
import { ItemTypes } from '../../../context/constants';

// Firebase
import { db } from "../../../firebase/firebase";
import { doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
import { getAuth} from "firebase/auth";
import Icons from '../../1atoms/icons/Icons';


export default function TypeItem({group}, i) {

    const auth = getAuth();
    const [inGroup, setInGroup] = useState('')
  
    // Drag and Drop 
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      
        accept: ItemTypes.BOX,
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
        drop: (item) => { 
          const studentPath = doc(db, `students/${item.name}`);

          // Check if in group
          async function fetchData() {
            const docSnap = await getDoc(studentPath);

            if (docSnap.exists()) {
                const docData = docSnap.data();
                const groupArray = docData.group;
                if (groupArray.includes(group.data.idgroup)) {
                  setInGroup(true)
                  setTimeout(() => {
                    // Remove class after animation
                    setInGroup(false)
                  }, "3000")
                } else {
                  setInGroup(false)
                }

            } else {
                console.log("No such document!");
            }
          }
          
          fetchData();
            
          // Time Out needed to for check to happen before write
          setTimeout(() => {
            // Update Student Group Array
            updateDoc(studentPath, {
                group: arrayUnion(group.data.idgroup)
            });
            
            // Update Teacher Group Array
            const groupPath = doc(db, `teachers/${auth.currentUser.uid}/groups/${group.data.idgroup}`);
            updateDoc(groupPath, {
              students: arrayUnion(item.name)
            });   
          }, "500")
            
        }
      }))
    
      const isActive2 = canDrop && isOver;
      let dropclass = styles.container; 
      if (isActive2) {
        dropclass += ` ${styles.willdrop}`;
      } else if (canDrop) {
        dropclass += ` ${styles.abledrop}`;
        
      } else {
        dropclass = styles.container;
      }


  return (
    <div className={`${dropclass}`} ref={drop} key={i}>

        <div className={`${styles.message} ${inGroup ? styles.ingroup : ''}`}>
          <Icons type="utility" icon="circle-check" size="small"/>
          <p>
            <strong>Already In Group</strong>
          </p>
        </div>

        <NavLink to={`/student-groups/${group.data.idgroup}`} className={({ isActive }) => isActive ? styles.active : undefined } >
            <img src={group.data.img} alt={group.data.namegroup}/>
            <p className={styles.label}>{group.data.namegroup}</p>
            <p className={styles.count}>{group.data.students.length}</p>
        </NavLink>
    </div>  
  )
}
