import React, {useState, useEffect, useContext} from 'react';
import styles from './listfeed.module.css';


// Context
import { TeacherContext } from "../../../context/teacher";

  // Firebase
  import { db } from "../../../firebase/firebase";
  import { collection, onSnapshot, query } from "firebase/firestore";
  import { getAuth } from "firebase/auth";

// Components
import Card from '../../2molecules/card/Card';
import CardActions from '../../2molecules/cardactions/CardActions';
import Button from '../../2molecules/button/Button';
import Image from '../../2molecules/image/Image';
import ModalAddPost from '../modalAddPost/ModalAddPost';
import List from '../../2molecules/list/List';
import Icons from '../../1atoms/icons/Icons';
import ButtonOptions2 from '../../2molecules/buttonoptions/ButtonOptions2';
import ButtonOptionItem from '../../2molecules/buttonoptionitem/ButtonOptionItem';
import ModalEditPost from '../modalEditPost/ModalEditPost';
import ModalDeletePost from '../modalDeletePost/ModalDeletePost';
import ModalWelcomeTeacher from '../modalWelcomeTeacher/ModalWelcomeTeacher';
import Date from '../../1atoms/date/date';




export default function ListFeed() {

  const auth = getAuth();
  const teacher = useContext(TeacherContext);
  const [update, setUpdate] = useState('')
  
  // Set Staff Image
  const [staffImage, setStaffImage] = useState('');
  useEffect(() => {  
    if (teacher.data) {
      setStaffImage(teacher.data.img);
    }
  }, [teacher]);



  // List School Posts
  const [loading, setSetLoading] = useState(false);
  const [error, setError] = useState('');
  const [length, setLength] = useState(0);
  const [schoolPosts, setSchoolPosts] = useState('');

  useEffect(() => {
    setSetLoading(true)
    const q = query(collection(db, `schools/ZrewvPrq457M6f8AVI0T/posts`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const posts = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
          posts.push({data: doc.data(), id: doc.id});
          setSchoolPosts(posts);
          setTimeout(() => {
            setSetLoading(false)
          }, 500);
          
      } else if (posts.length===0) { 
        setSetLoading(false)
      } else {
        setError('Error loading posts')
        setSetLoading(false)
      }
    });
    });

    setSetLoading(false)
    return unsubscribe;
    
  }, [update]);

  useEffect(() => {
    if (schoolPosts) {
    setLength(schoolPosts.length);
    
    } else {
      setLength(0);
    }
  }, [schoolPosts, update]);


  // Sort by timestamp 
  if (schoolPosts) {
    schoolPosts.sort(function(a, b){return b.data.addedbywhen - a.data.addedbywhen});
  }


  // Post Options

  const [setCardOptions, setIsCardOptions] = useState('');
  const [editPostID, setEditPostID] = useState('');
  const [deletePostID, setDeletePostID] = useState('');
  

function optionsPost(post) { 
  let posttid = post.id;
  const optionsPost = 'optionsPost' + posttid;
  const options = document.getElementById(optionsPost);
  options.toggleAttribute('open');
  setIsCardOptions(options);
}

  // Edit Post
function editPost(post) {
  setCardOptions.removeAttribute('open');
  window.editPostModal.showModal();
  setEditPostID(post);
}

// Delete Post
function deletePost(post) {
  setCardOptions.removeAttribute('open');
  setUpdate(post.id);
  window.deletePostModal.showModal();
  setDeletePostID(post);
}

useEffect(() => {
  if (teacher.data) {
    if (teacher.data.login===0 & teacher.loading===false) {
      window.welcomeTeacherModal.showModal();
      console.log('hello');
    }
  }
}, [teacher])

  





  return (
    <>
    
    <ModalAddPost teacherimg={staffImage} auth={auth}/>
    <ModalEditPost post={editPostID}/>
    <ModalDeletePost post={deletePostID} />
    <ModalWelcomeTeacher auth={auth}/>
    
    
      <CardActions type='center'
        left={<Image loading={teacher.loading} type='profile' size='small' img={staffImage}/>} 
        middle={
          <form className={styles.fullWidth}>
            <input onClick={() => window.addPostModal.showModal()} className={styles.fullWidth} type="text" placeholder="What's on your mind?"/>
          </form>
          
          } 
        right={<Button click={() => window.addPostModal.showModal()}>Post</Button>}>
      </CardActions>
    

      <List loading={loading} length={length} error={error} none={'No Posts'}>
        <>
          {length && schoolPosts.map((post, i) => (
            <section key={i} className={styles.container}>
              <Card>
              {post.data.addedbyid===auth.currentUser.uid && 
                <Button id={'button' + post.id} type2={'button'} type="icon" class={styles.options} click={() => optionsPost(post)}>
                  <Icons type='utility' icon='ellipsis-vertical' size='small'/> 
                </Button>
              }

                <ButtonOptions2 type={'optionsPost'} id={post.id} >
                  <ButtonOptionItem click={() => editPost(post)} icon={'pencil'}>Edit Post</ButtonOptionItem>
                  <ButtonOptionItem click={() => deletePost(post)} icon={'xmark'}>Delete Post</ButtonOptionItem>
                </ButtonOptions2>

                <article className={styles.post} >
                  <div className={styles.header}>
                    <div className={styles.left}>
                      <Image type='profile' size='tiny' img={post.data.teacherimg}/> 
                      {post.data.addedbyname}
                    </div>
                    
                    <p className='details'><Date date={post.data.addedbywhen}/></p>
                  </div>
                  {post.data.postimg && <div className={styles.imagePost} style={{ backgroundImage: `url(${post.data.postimg})` }} >
                    {post.data.type==='welcome' && <Image type='profile' size='medium' img={post.data.teacherimg}/> }
                  </div>}
                  <div className={styles.body}>
                    <p>{post.data.post}</p>
                  </div>  
                </article> 
              </Card>
            </section>
          ))}
        </>
      </List>
   
    </>
    
  );


}