import React, { useEffect, useState } from 'react';
import styles from './teacherpage.module.css';

// Components
import Header from '../../3organisms/header/Header';
import ListTeachers from '../../3organisms/listteachers/ListTeachers';
import PanelAction from '../../3organisms/panelaction/PanelAction';
import MainPanel from '../../4templates/mainPanel/MainPanel';

import FormProvider from "../../../context";

// Firebase
import { db } from "../../../firebase/firebase";
import { collection, onSnapshot, where, query } from "firebase/firestore";


export default function TeacherPage() {

  document.title = 'teachers';

  // Menu State
  const [activeState, setActiveState] = useState('Information');

  // Data Students and Groups
  const [allTeachers, setallTeachers] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "teachers"), where("schoolsid", "array-contains", "ZrewvPrq457M6f8AVI0T"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const teachers = [];
    querySnapshot.forEach((doc) => {
  
        teachers.push({data: doc.data(), id: doc.id});
        setallTeachers(teachers);
        
    });
    return unsubscribe;
   
    });
    
  }, []);

 

  return (
    <FormProvider> 
      <div id="teacherPage">
        <Header type='Teacher' state={activeState} setActiveState={setActiveState} />
        <div className={styles.section}> 
          <MainPanel 
            cards={<ListTeachers state={activeState} teachers={allTeachers}/>} 
            panel={<PanelAction state={activeState} />}
          />
        </div>
      </div>
    </FormProvider>
  );
}