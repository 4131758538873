import React, {useEffect, useState} from 'react';
import styles from './addimageteacherprofile.module.css';

import imageCompression from 'browser-image-compression';

// Firebase
import { db } from "../../../firebase/firebase";
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, updateProfile } from "firebase/auth";





export default function AddImageTeacherProfile() {

  const storage = getStorage();
  const auth = getAuth();

  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(true);


   // Current Image URL from Database
   useEffect(() => {
    setLoading(true);
    const teacherPath = doc(db, `teachers/${auth.currentUser.uid}`);
    const unsub = onSnapshot(teacherPath, (doc) => {
      if (doc.exists) {
        const docData = doc.data();
        setImage(docData.img);
      } else {
        setImage('../../../images/system-no-image.png');
      }
      setLoading(false);
    });
    
    return unsub;   
    
    }, [auth]);



  // Image Upload
  const changeHandler = (e) => {
    setLoading(true);
    let selected = e.target.files[0];
    const profileRef = ref(storage, `ZrewvPrq457M6f8AVI0T/teachers/${auth.currentUser.uid}/${selected.name}`);

    //reduce
    const reduceOptions = {
      maxSizeMB: .3,
      maxWidthOrHeight: 600,
      useWebWorker: true
    }

    imageCompression(selected, reduceOptions).then(function (compressedFile){

      const uploadProfile = uploadBytesResumable(profileRef, compressedFile);

      const teacherPath = new doc(db, `teachers/${auth.currentUser.uid}`);
      
      uploadProfile.on('state_changed', (snapshot) => {
          console.log('uploaded');
      },(error) => {
          console.log(error);
      }, async () => {
          getDownloadURL(uploadProfile.snapshot.ref).then((downloadURL) => {
              setImage(downloadURL);
              // Update Image Auth
              updateProfile(auth.currentUser, {photoURL: downloadURL})
              // Update Image URL and Name In Database
              const formSignUpData = {};
              formSignUpData.img = downloadURL;
              formSignUpData.imgname = selected.name;
              updateDoc(teacherPath, formSignUpData);  
              setLoading(false);
          });
      });
    })

    
    
    
  }


  return (
    <div className={styles.container}>

        <div className={styles.imageContainer}>
          {loading===true ? <div className={styles.loading}><p>Loading</p></div> : 
            <img className={styles.profileImg} src={image}/>
          }
        </div>
        
        <form>
            <input type='file' onChange={changeHandler}/>
        </form>
    </div>
  )
}
