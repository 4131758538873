import React from 'react'
import styles from './cardsectionsel.module.css';

// Components
import Date from '../../1atoms/date/date'
import Icons from '../../1atoms/icons/Icons'

export default function CardSectionSEL({student, view}) {

    let status;

    switch (student.check) {
    case 'Calm':
        status = styles.calm;
        break;
    case 'Stressed':
        status = styles.stressed;
        break;
    case 'Angry':
        status = styles.angry;
        break;
    default:
        status = styles.nocheck;
    }


  return (
    <div className={`${styles.section} ${view===true && styles.list}`}>
            <p className={`${styles.status} ${status}`}> 
              <Icons icon={student.check}/> 
              {student.check} 
              {view===true && <span className={`details ${styles.date}`}><Date date={student.checkdate}/></span>}
            </p>
            

            {view===true && 
            <>
                {student.check==='Nocheck' ? ' ' : 
                    <p className={` details ${styles.comments}`}>
                        
                        
                        <span className={styles.sectionOne}>
                            {student.checkcomment}<br/>
                            <span className={styles.name}>Staff: {student.checkteacher}</span>
                        </span>
                        
                    </p>
                }
            </>
            }

    </div>
  )
}
