import React from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';

// Firebase
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

// Components
import StartTemplate from '../../4templates/startTemplate/StartTemplate';
import Button from '../../2molecules/button/Button';



export default function PasswordResetPage() {

  const navigate = useNavigate();

  document.title = 'password reset';

  const { register, handleSubmit: handleSubmit, formState: {errors}} = useForm();


  const onSubmit = (async (values) => {

    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, values.email); 
      console.log('Email was sent');
      navigate('../signin'); 

    } catch (error) {
      console.log(error);
      console.log('Your email was not found');
      
    }

  })


  return (
    <StartTemplate title={'password reset'}> 
    
    
        <form className="panelForm" autoComplete={"off"}>
        <p>Enter your email address to reset your password.</p><br/>
              
            <div className="formContainer">
                <label>Email:</label>
                
                <input 
                  autoComplete={'rand0mstr1nG'} 
                  type='email' 
                  {...register('email', { required: true })} 
                />
                {errors.email && <div className="error">Must enter email address.</div>}
            </div>

            <Button type={'primary'} click={handleSubmit(onSubmit)}>Reset Password</Button>

        </form>
    
    </StartTemplate>
  )
}
