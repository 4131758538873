import React, {useState} from 'react'
import styles from './panelsel.module.css';
import { useForm } from 'react-hook-form';

// Context
import { useFormData } from "../../../context";

// Firebase
import { doc, updateDoc, collection, serverTimestamp, setDoc  } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { getAuth } from "firebase/auth";

// Components
import Icons from '../../1atoms/icons/Icons';
import Button from '../../2molecules/button/Button';

export default function PanelSEL({state}) {

const { setFormValues, data } = useFormData();
const { register, handleSubmit: handleSubmit2, formState: {errors}, reset} = useForm();



  const onSubmit = (async (values) => {

    const auth = getAuth();
    
    const selectedStudents = data;
    const panelSelection = values;
    
      const arrayStudents = Object.entries(selectedStudents);
  
      arrayStudents.map(async (student) =>
        {if (student[0]!=='done'){
          if(student[1]===true) {
            let studentid = student[0];
            let studentPath = new doc(db, `students/${studentid}`);
            

            await updateDoc(studentPath, {
              check: panelSelection.statustype,
              checkcomment: panelSelection.comments,
              checkdate: serverTimestamp(),
              checkteacher: auth.currentUser.displayName,
            });

            let studentSELPath = doc(collection(db, `students/${studentid}/sel`)); 
            const formSELStatus = {...values};
            formSELStatus.date = serverTimestamp();
            formSELStatus.teacherid = auth.currentUser.uid;
            formSELStatus.teachername = auth.currentUser.displayName;

            setDoc(studentSELPath, formSELStatus);



          }
        }}
      )
    
    setFormValues({done: true})
    reset();
  })


  return (
    <div className={styles.container}>
    
    <form className={`panelForm ${styles.sel}`} autoComplete="off">
        <div className="formContainer">
            <label htmlFor="1" className={`${styles.calm} ${styles.radio} radioButton`}>
              <Icons over size="large" icon='Calm'/>
              <input
                {...register('statustype', { required: true })}
                type="radio"
                name="statustype"
                value="Calm"
                className={`form-check-input`}
                id="1"
              />{' '}
              Calm
            </label>

            <label htmlFor="2" className={`${styles.radio} ${styles.stressed} radioButton`}  >
              <Icons over size="large" icon='Stressed'/>
              <input
                {...register('statustype', { required: true })}
                type="radio"
                name="statustype"
                value="Stressed"
                className={`form-check-input`}
                id="2"
              />{' '}
              Stressed
            </label>

            <label htmlFor="3" className={`${styles.radio} ${styles.angry} radioButton`}>
            <Icons over size="large" icon='Angry'/>
              <input
                {...register('statustype', { required: true })}
                type="radio"
                name="statustype"
                value="Angry"
                id="3"
              />{' '}
               Angry
            </label>
          {errors.statustype && <div className="error">Must select one emotional state.</div>}
        </div>

        <div className="formContainer">
          <label><strong>Comments</strong></label>
          <textarea
          type='text'
          {...register('comments', { required: true })}
          />
          {errors.comments && <div className="error">Required explaintation.</div>}
        </div>

        <input
          type='hidden'
          {...register('done', {value: true})}
        />
 
      </form>
    <div className={styles.buttonContainer}>
      <Button click={handleSubmit2(onSubmit)} state={state} >Check</Button>
    </div>
    </div>
  )
}
