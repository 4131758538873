import React, {useState, useEffect} from 'react';
import styles from '../../4templates/adminTemplate/admintemplate.module.css';

import { useForm } from 'react-hook-form';

// Firebase
import { db } from "../../../firebase/firebase";
import { collection, onSnapshot, where, query, doc, updateDoc } from "firebase/firestore";

// Components
import Header from '../../3organisms/header/Header';
import AdminTemplate from '../../4templates/adminTemplate/AdminTemplate';
import Image from '../../2molecules/image/Image';
import Icons from '../../1atoms/icons/Icons';



export default function AdminMainPage() {

  document.title = 'admin';

  const { register, setValue, watch} = useForm();


  // Application Settings
  useEffect(() => {
    
        const appPath = doc(db, `application/settings`);
        const unsub = onSnapshot(appPath, (doc) => {
        if (doc.exists) {
          const docData = doc.data();
          
          [
          {name: 'earlyaccess', value: docData.earlyaccess},
          ].forEach(({name, value}) => setValue(name, value))
        }

        });
        return unsub; 
    
}, []);

useEffect(() => {
  const subscription = watch((selected) => {

    const appPath = doc(db, `application/settings`);
    const schoolSettingsData = {...selected};
    updateDoc(appPath, schoolSettingsData);  
  })

  return () => {
    subscription.unsubscribe();
  }
}, [watch]);



  // Data Students and Groups
  const [allTeachers, setallTeachers] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "teachers"), where("schoolsid", "array-contains", "ZrewvPrq457M6f8AVI0T"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const teachers = [];
    querySnapshot.forEach((doc) => {
  
        teachers.push({data: doc.data(), id: doc.id});
        setallTeachers(teachers);
        
    });
    return unsubscribe;
   
    });
    
  }, []);

  

  return (
    <>
        <Header/>
        <AdminTemplate>

        <div className="container">
        <h1>Admin</h1>
          <h2>Early Access</h2>
          <form className="panelForm" autoComplete={"off"}>
            <div className="formContainer">
            <label htmlFor="1" className={`radioButton`}>
            <div className="radioButtonCheck"><Icons type="utility" icon="check" /></div>
              <input
                {...register('earlyaccess')}
                type="radio"
                name="earlyaccess"
                value="On"
                id="1"
              />{' '}
               On
            </label>

            <label htmlFor="2" className={`radioButton`}>
            <div className="radioButtonCheck"><Icons type="utility" icon="check" /></div>
              <input
                {...register('earlyaccess')}
                type="radio"
                name="earlyaccess"
                value="Off"
                id="2"
              />{' '}
               Off
            </label>
            </div>          
          </form>

          <h2>Staff</h2>
          {allTeachers.map((teacher, i) =>  (
            <div className={styles.row} key={i}>
              <Image type="profile" size="small" img={teacher.data.img} />
              <p>{teacher.data.firstName} {teacher.data.lastName}</p>
            </div>
          ))}
          </div>
        </AdminTemplate>
    </>
  )
}
