import React, {useState} from 'react';
import styles from './modaladdpost.module.css';

import { useForm } from 'react-hook-form';
import imageCompression from 'browser-image-compression';

// Firebase
import { db } from "../../../firebase/firebase";
import { setDoc, doc, collection, serverTimestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Components
import Button from '../../2molecules/button/Button'
import Modal from '../../2molecules/modal/Modal'
import Image from '../../2molecules/image/Image';
import Icons from '../../1atoms/icons/Icons';

export default function ModalAddPost({auth, teacherimg}) {

    const [loading, setLoading] = useState('');

    // Submit School Posts
    const { register, handleSubmit, formState: {errors}, reset} = useForm();

    // Image states
    const [previewImage, setPreviewImage] = useState('');
    const [uploadImage, setUploadImage] = useState('');
    
    const [nameImage, setNameImage] = useState('');
    const storage = getStorage();

    // Image Preview
    const changeHandler = (e) => {
        let preview = URL.createObjectURL(e.target.files[0]);
        setPreviewImage(preview);
        let upload = e.target.files[0];
        setUploadImage(upload);
    }

    // Submit Post
    const onSubmit = (async (values) => {

      setLoading('loading');
    

      try {

        const schoolPostRef = doc(collection(db, `schools/ZrewvPrq457M6f8AVI0T/posts`)); 
        
        if (uploadImage) {     
            const postStorageRef = ref(storage, `ZrewvPrq457M6f8AVI0T/posts/${schoolPostRef.id}/${uploadImage.name}`);

            //reduce
            const reduceOptions = {
                maxSizeMB: .3,
                maxWidthOrHeight: 600,
                useWebWorker: true
            }

            imageCompression(uploadImage, reduceOptions).then(function (compressedFile){
                const uploadPostImg = uploadBytesResumable(postStorageRef, compressedFile);
                setNameImage(uploadImage.name);
                uploadPostImg.on('state_changed', (snapshot) => {
                    console.log('uploaded');
                },(error) => {
                    console.log(error);
                }, async () => {
                    getDownloadURL(uploadPostImg.snapshot.ref).then((downloadURL) => {
    
                        const formNewPost = {...values};
                        formNewPost.addedbywhen = serverTimestamp();
                        formNewPost.addedbyid = auth.currentUser.uid;
                        formNewPost.addedbyname = auth.currentUser.displayName;
                        formNewPost.teacherimg = teacherimg;
                        formNewPost.postimg = downloadURL;
                        formNewPost.postimgname = nameImage;

                        setDoc(schoolPostRef, formNewPost);

                    });
                });
            })

            

            } else {

            // New Post
            const formNewPost = {...values};
            formNewPost.addedbywhen = serverTimestamp();
            formNewPost.addedbyid = auth.currentUser.uid;
            formNewPost.addedbyname = auth.currentUser.displayName;
            formNewPost.teacherimg = teacherimg;
            formNewPost.postimg = '';
            formNewPost.postimgname = '';

            setDoc(schoolPostRef, formNewPost);

            }

        
        
  
      } catch (error) {
          console.log(error);       
      }
      reset();  
      setLoading('');
      setUploadImage('');
      setPreviewImage('');
      
      window.addPostModal.close();
  })


    // Clears Modal if Closes
    function modalClear() {
        reset();
        setPreviewImage(''); 
        setUploadImage('');
    }

  return (
    <Modal  
        open="open"
        title="Add Post" 
        modalid='addPostModal'
        clear={()=> modalClear()}
        button={<Button type={'primary'} click={ handleSubmit(onSubmit) } state={loading} >Post</Button>}
    >

    <form>  
        <div className="formContainer">
            <div className={styles.containercenter}><Image type='profile' size='tiny' img={teacherimg}/><p><strong>{auth.currentUser.displayName}</strong></p></div>
            <textarea {...register('post', { required: true })}  rows="3" type="text" placeholder="What's on your mind?"/>
            {errors.post && <div className="error">Must enter a post</div>}
            
            <label className={styles.imageIcon} htmlFor="file-upload">
                <Icons type="utility" icon="image"/>
            </label>
             <input type='file' id="file-upload" className={styles.inputFile} onChange={changeHandler}/>
        </div>
        <div id="imagePreview" className="formContainer">
            {previewImage && <div className={styles.imagePreview} style={{ backgroundImage: `url(${previewImage})` }} ></div>}    
        </div>
    </form>
    
    </Modal>
  )
}
