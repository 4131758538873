import React, {useState, useEffect} from 'react';
import styles from './sectionteacherinfo.module.css';

import { useForm } from 'react-hook-form';


// Components
import Button from '../../2molecules/button/Button';
import { useNavigate, NavLink } from "react-router-dom";

// Firebase
import { db } from "../../../firebase/firebase";
import { getAuth, updateProfile } from "firebase/auth";
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';


export default function SectionTeacherInfo() {

    const auth = getAuth();
    const navigate = useNavigate();

    const [teacher, setTeacherProfile] = useState('');
    const [loading, setLoading] = useState('');

    const { register, handleSubmit: handleSubmit, formState: {errors}, setValue} = useForm();

    // Set Existing Information In Form
    useEffect(() => {
        const teacherPath = doc(db, `teachers/${auth.currentUser.uid}`);
        const unsub = onSnapshot(teacherPath, (doc) => {
          if (doc.exists) {
            const docData = doc.data();
            setTeacherProfile(docData);
            [
              {name: 'firstName', value: docData.firstName},
              {name: 'lastName', value: docData.lastName},
              {name: 'email', value: docData.email},
              {name: 'school', value: 'Motivating Systems School'},
            ].forEach(({name, value}) => setValue(name, value))
          }

        });
        return unsub;   
    }, [auth]);

    

   

    
    // Update Information
    const onSubmit = (async (values) => {
      setLoading('loading');
      try {
        const teacherPath = new doc(db, `teachers/${auth.currentUser.uid}`);
        const formSignUpData = {...values};
        updateProfile(auth.currentUser, {displayName: values.firstName + ' ' + values.lastName})
        await updateDoc(teacherPath, formSignUpData);  
      } catch(error) {
        console.log(error);
      }
      setLoading('');
      navigate('/feed');
    })

    

    
    

  return (
    <div>
    <h1>Your Profile</h1>
        <form className="panelForm" autoComplete={"off"}>
            <div className="formContainer">
              <label>First Name:</label>
              <input 
               
                type='text' 
                name='firstName'
                {...register('firstName', { required: true })} 
              />
              {errors.firstName ? <div className="error">Must enter first name.</div> :
              null }
          </div>

          <div className="formContainer">
              <label>Last Name:</label>
              <input 
                type='text'
                name='lastName'
                 
                {...register('lastName', { required: true })} 
              />
              {errors.lastName ? <div className="error">Must enter last name.</div> :
              null }
          </div>

          <div className="formContainer">
              <label>Email:</label>
              <input 
                
                type='email' 
                name='email'
                {...register('email', { required: true })} 
              />
              {errors.email ? <div className="error">Must enter email.</div> :
              null }
          </div>

          <div className="formContainer">
              <label>School:</label>
              <input 
                type='text' 
                name='school'
                {...register('school', { required: true })} 
              />
              {errors.school ? <div className="error">School value can not be changed.</div> :
              null }
          </div>
            
        </form>

        <div className={styles.buttonContainer}>
            <NavLink to="/feed"><Button>Cancel</Button></NavLink>
            <Button type="primary" state={loading} click={handleSubmit(onSubmit)}>Save</Button> 
        </div>
    </div>
  )
}
