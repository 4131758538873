import React, { useEffect, useState } from 'react';
import styles from './studentgrouppage.module.css';

// Components
import Header from '../../3organisms/header/Header';
import ListStudents from '../../3organisms/liststudents/ListStudents';
import PanelAction from '../../3organisms/panelaction/PanelAction';
import MainPanel from '../../4templates/mainPanel/MainPanel';

// Context
import FormProvider from "../../../context";
import {useParams} from "react-router-dom";

// Firebase
import { db } from "../../../firebase/firebase";
import {collection, onSnapshot, where, query, doc } from "firebase/firestore";


export default function StudentGroupPage() {
  
  document.title = 'groups';
  let groupID = useParams();

  // Groups
  const [groupListing, setGroupListing] = useState([]);

  useEffect(() => {
    setGroupListing('');
    const q = query(collection(db, "students"), where("group", "array-contains", groupID.id));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const groups = [];
    querySnapshot.forEach((doc) => {
        
        groups.push({data: doc.data(), id: doc.id});
        setGroupListing(groups);
        
    });
    return unsubscribe;
   
    });
    
  }, [groupID.id]);

  // Menu State
  const [activeState, setActiveState] = useState('SEL');

  return (
    <FormProvider> 
      <div className="page lists-show">
        <Header type='Student' state={activeState} setActiveState={setActiveState} />
        <div className={styles.section}> 
          <MainPanel 
            cards={<ListStudents state={activeState} students={groupListing} group={true} />} 
            panel={<PanelAction state={activeState} />}
          />
        </div>
      </div>
    </FormProvider>
  );
}