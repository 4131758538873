import React, {useEffect, useState} from 'react'
import styles from './modaleditpost.module.css';

import { useForm } from 'react-hook-form';
import imageCompression from 'browser-image-compression';

// Firebase
import { db } from "../../../firebase/firebase";
import { updateDoc, doc ,onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Components
import Button from '../../2molecules/button/Button'
import Modal from '../../2molecules/modal/Modal'




export default function ModalEditPost({post}) {
    
    const [previewImage, setPreviewImage] = useState('');
    const [uploadImage, setUploadImage] = useState('');
    const [nameImage, setNameImage] = useState('');
    const storage = getStorage();

    const [loading, setLoading] = useState('');

    const { register, handleSubmit, formState: {errors}, setValue} = useForm();

    // Image Preview
    const changeHandler = (e) => {
      console.log('hello');
      let preview = URL.createObjectURL(e.target.files[0]);
      setPreviewImage(preview);
      let upload = e.target.files[0];
      setUploadImage(upload);
  }

    // Set Existing Information In Form
    useEffect(() => {
        if (post) {

            const postPath = doc(db, `schools/ZrewvPrq457M6f8AVI0T/posts/${post.id}`);
            const unsub = onSnapshot(postPath, (doc) => {
            if (doc.exists) {
                
                const docData = doc.data();
                setPreviewImage(docData.postimg);
                [
                {name: 'post', value: docData.post},
                ].forEach(({name, value}) => setValue(name, value))
            }
  
            });
            return unsub; 
        }  
    }, [post]);

     // Update Information
     const onSubmit = (async (values) => {
        setLoading('loading');
        try {
          const postPath = new doc(db, `schools/ZrewvPrq457M6f8AVI0T/posts/${post.id}`);
          if (uploadImage) {  
            const postStorageRef = ref(storage, `ZrewvPrq457M6f8AVI0T/posts/${post.id}/${uploadImage.name}`);

            //reduce
            const reduceOptions = {
              maxSizeMB: .3,
              maxWidthOrHeight: 600,
              useWebWorker: true
            }

            imageCompression(uploadImage, reduceOptions).then(function (compressedFile){
                const uploadPostImg = uploadBytesResumable(postStorageRef, compressedFile);
              setNameImage(uploadImage.name); 
              uploadPostImg.on('state_changed', (snapshot) => {
                  console.log('uploaded');
              },(error) => {
                  console.log(error);
              }, async () => {
                getDownloadURL(uploadPostImg.snapshot.ref).then((downloadURL) => {
                    const formNewPost = {...values};
                    formNewPost.postimg = downloadURL;
                    formNewPost.postimgname = nameImage;

                    updateDoc(postPath, formNewPost);

                });
              });
            })

            
            
             
          } else {
            
            const formSignUpData = {...values};
            await updateDoc(postPath, formSignUpData);  
          }
          
        } catch(error) {
          console.log(error);
        }
        setLoading('');
        window.editPostModal.close();
        })


  return (
    <Modal 
    title="Edit Post" 
    modalid='editPostModal'
    button={<Button type={'primary'} click={ handleSubmit(onSubmit) } state={loading}>Save</Button>}
    >
    
        <form className="panelForm" autoComplete={"off"}>
            <div className="formContainer">
              <label>Post:</label>
              <textarea
               
                type='text' 
                name='post'
                {...register('post', { required: true })} 
              />
              {errors.post ? <div className="error">Must enter a name.</div> :
              null }
            </div>



            <div id="imagePreview" className="formContainer">

              <div className={styles.buttonImageContainer} >
                <Button type2='button' class={styles.imageButton}>
                  <label className={styles.imageLabelButton} htmlFor="file-upload2">
                      Change Image
                  </label>
                  <input type='file' id="file-upload2" className={styles.inputFile} onChange={changeHandler}/>
                </Button> 
              </div>

              {previewImage && <div className={styles.imagePreview} style={{ backgroundImage: `url(${previewImage})` }} ></div>}    

            </div>         
        </form>
    
    </Modal>
  )
}
