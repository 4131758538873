import React, {useState, useEffect} from 'react';

import { useForm } from 'react-hook-form';

// components
import Button from '../../2molecules/button/Button';
import Modal from '../../2molecules/modal/Modal';
import UpdateImageGroup from '../updateImageGroup/UpdateImageGroup';

// Firebase
import { db } from "../../../firebase/firebase";
import { updateDoc, onSnapshot, doc } from "firebase/firestore";
import { getAuth} from "firebase/auth";



export default function ModalEditGroup({group}) {

    const auth = getAuth();

    const [loading, setLoading] = useState('');

    const { register, handleSubmit, formState: {errors}, setValue} = useForm();

     // Set Existing Information In Form
     useEffect(() => {
      if (group) {
          const groupPath = doc(db, `teachers/${auth.currentUser.uid}/groups/${group.id}`);
          const unsub = onSnapshot(groupPath, (doc) => {
          if (doc.exists) {
              const docData = doc.data();
              
              [
              {name: 'namegroup', value: docData.namegroup},
              ].forEach(({name, value}) => setValue(name, value))
          }

          });
          return unsub; 
      }  
  }, [group]);

    // Update Information
    const onSubmit = (async (values) => {
      setLoading('loading');
      try {
        const groupPath = new doc(db, `teachers/${auth.currentUser.uid}/groups/${group.id}`);
        const formSignUpData = {...values};
        await updateDoc(groupPath, formSignUpData);  
        
      } catch(error) {
        console.log(error);
      }
      setLoading('');
      window.editGroupModal.close();
      })

  return (
    <Modal 
    title="Edit Group" 
    modalid='editGroupModal'
    button={<Button type={'primary'} click={ handleSubmit(onSubmit) } state={loading}>Save</Button>} 
    >
    {group && <UpdateImageGroup groupid={group.id} teacherid={auth.currentUser.uid} /> }


        <form className="panelForm" autoComplete={"off"}>
            <div className="formContainer">
              <label>Name:</label>
              <input 
               
                type='text' 
                name='namegroup'
                {...register('namegroup', { required: true })} 
              />
              {errors.namegroup ? <div className="error">Must enter a name.</div> :
              null }
            </div>          
        </form>
    </Modal>

  )
}
