import React from 'react';

export default function Date({date}) {

  

    // Date Formating
  function doDaDate(date) {
    if (date) {
      
      let df = date.toDate();
      let dfl = df.toLocaleDateString();
      return dfl;
    }
  }

  return (
    <>{doDaDate(date)}</>
  )
}
