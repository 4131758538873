import React, {useEffect, useState} from 'react';
import styles from './updateimagegroup.module.css';

import imageCompression from 'browser-image-compression';

// Firebase
import { db } from "../../../firebase/firebase";
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export default function UpdateImageGroup({groupid, teacherid}) {

const storage = getStorage();

const [image, setImage] = useState('');
const [loading, setLoading] = useState(true);

// Current Image URL from Database
useEffect(() => {
    if (groupid) {
        setLoading(true);
        const groupPath = doc(db, `teachers/${teacherid}/groups/${groupid}`);
        const unsub = onSnapshot(groupPath, (doc) => {
        if (doc.exists) {
            const docData = doc.data();
            setImage(docData.img);
        } else {
            setImage('../../../images/system-no-image.png');
        }
        setLoading(false);
        });
        
        return unsub; 
    }  
    
    }, [groupid]);


    // Image Upload
  const changeHandler = (e) => {
    setLoading(true);
    let selected = e.target.files[0];
    const storageRef = ref(storage, `ZrewvPrq457M6f8AVI0T/teachers/${teacherid}/groups/${groupid}/${selected.name}`);

     //reduce
     const reduceOptions = {
      maxSizeMB: .3,
      maxWidthOrHeight: 600,
      useWebWorker: true  
    }

    imageCompression(selected, reduceOptions).then(function (compressedFile){
      // Update Image Storage
    const uploadGroup = uploadBytesResumable(storageRef, compressedFile);

    const groupPath = new doc(db, `teachers/${teacherid}/groups/${groupid}`);
    
    uploadGroup.on('state_changed', (snapshot) => {
        console.log('uploaded');
    },(error) => {
        console.log(error);
    }, async () => {
        getDownloadURL(uploadGroup.snapshot.ref).then((downloadURL) => {
            setImage(downloadURL);
            
            // Update Image URL and Name In Database
            const formSignUpData = {};
            formSignUpData.img = downloadURL;
            formSignUpData.imgname = selected.name;
            updateDoc(groupPath, formSignUpData);  
            setLoading(false);
        });
    });
    })
    
    
    
  }

  return (
    <div className={styles.container}>

        <div className={styles.imageContainer}>
          {loading===true ? <div className={styles.loading}><p>Loading</p></div> : 
            <img className={styles.profileImg} src={image}/>
          }
        </div>
        
        <form>
            <input type='file' onChange={changeHandler}/>
        </form>
    </div>
  )
}
