import React, {useState, useEffect} from 'react';
import imageCompression from 'browser-image-compression';
import styles from './addimageprofile.module.css';

// Firebase
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export default function AddImageProfile({setProfileImg, imageStatus, studentid, setNewStudentID, setProfileImgName}) {

  const storage = getStorage();

  const [image, setImage] = useState('');

    useEffect(() => {
        setImage('');
        // console.log(imageStatus);    
    }, [imageStatus]);

  const changeHandler = (e) => {
    let selected = e.target.files[0];
    const profileRef = ref(storage, `ZrewvPrq457M6f8AVI0T/students/${studentid}/${selected.name}`);


    //reduce
    const reduceOptions = {
        maxSizeMB: .3,
        maxWidthOrHeight: 600,
        useWebWorker: true
    }

    imageCompression(selected, reduceOptions).then(function (compressedFile){
        const uploadProfile = uploadBytesResumable(profileRef, compressedFile);
    
        uploadProfile.on('state_changed', (snapshot) => {
            console.log('uploaded');
        },(error) => {
            console.log(error);
        }, async () => {
            getDownloadURL(uploadProfile.snapshot.ref).then((downloadURL) => {
                setImage(downloadURL);
                setProfileImg(downloadURL);
                setNewStudentID(studentid);
                setProfileImgName(selected.name);
            });
        });
    })

    
    
  }

  return (
    <article className={styles.container}>
        <div className={styles.onethird}>
            {!image ? <img src='../../../images/system-no-image.png'/> : <img src={image}/>}
            
        </div>
        <div className={styles.twothirds}>
            <form>
                <input type='file' onChange={changeHandler}/>
            </form>
        </div>
    </article>
  )
}
