import React, {useState} from 'react';

import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { useForm } from 'react-hook-form';

import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

// Firebase
import { db } from "../../../firebase/firebase";
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, collection, serverTimestamp, setDoc, addDoc, updateDoc, arrayUnion  } from 'firebase/firestore';

// Components
import Button from '../../2molecules/button/Button';
import StartTemplate from '../../4templates/startTemplate/StartTemplate';
import Icons from '../../1atoms/icons/Icons';

export default function UpdatePage() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState('');
  const [firebaseErrors, setFirebaseErrors] = useState(false);

  // Email and Password Sign up
  const { register, handleSubmit: handleSubmit, formState: {errors}} = useForm();

  const onSubmit = (async (values) => {
    setFirebaseErrors(false);
    setLoading('loading');
    try {
      const auth = getAuth();
      const userCrednetial = createUserWithEmailAndPassword(auth, values.email, values.password);
      const user = await userCrednetial;
      // console.log(user.user.uid);
      updateProfile(auth.currentUser, {displayName: values.firstName + ' ' + values.lastName})
      
      // Adding A New School Information
      // const schoolPath = await addDoc(collection(db, 'schools'), {
      //   school: values.SchoolName,
      //   teachers: arrayUnion(user.user.uid)
      // });
      // await updateDoc(schoolPath, {schoolid: schoolPath.id});

      // Temp Updating Motivating School Doc
      const schoolPath = doc(db, `schools/ZrewvPrq457M6f8AVI0T`);
      await updateDoc(schoolPath, {
        teachers: arrayUnion(user.user.uid)
      });

      // Teacher Information
      const formSignUpData = {...values};
      // Edit Values from Form
      delete formSignUpData.password; 
      delete formSignUpData.SchoolName; 
      formSignUpData.timestamp = serverTimestamp();
      formSignUpData.img = '../../../images/system-no-image.png';
      // Database Folder Names
      const teacherPath = new doc(db, `teachers/${user.user.uid}`);
      await setDoc(teacherPath, formSignUpData);

      await updateDoc(teacherPath, {
        // schoolsid: arrayUnion(schoolPath.id),
        // schools: arrayUnion(values.SchoolName),
        schoolsid: arrayUnion('ZrewvPrq457M6f8AVI0T'),
        schools: arrayUnion('Motivating Systems School'),
        login: 0,
      });

      if (user) {
        navigate('/feed');
      }

    } catch (error) {
      console.log(error);
      setFirebaseErrors(true);
    }
    setLoading('');
 
    }
)


  document.title = 'stay updated';

  const { loaded, error, formCreated } = useHubspotForm({
    portalId: '24028836',
    formId: '20aaf6d5-aa3b-4566-9316-767f209316ec',
    target: '#my-hubspot-form'
  });


  return (
        
          <StartTemplate title={'stay updated'} >
          <div><p>If you would like to stay updated for <strong>early access</strong>. Please fill out the form below.</p><br/></div>
            <div id="my-hubspot-form"></div>
              
{/* 
              <form className="panelForm" autoComplete={"off"}>
              <p>If you would like to stay updated for <strong>early access</strong>. Please fill out the form below.</p><br/>
                    
                  <div className="formContainer">
                      <label>First Name:</label>
                      <input 
                        autoComplete={'rand0mstr1nG'} 
                        type='text' 
                        {...register('firstName', { required: true })} 
                      />
                      {errors.firstName ? <div className="error">Must enter first name.</div> : 
                      firebaseErrors ? <div className="error">Please checkbox entry.</div> : 
                      null }
                  </div>

                  <div className="formContainer">
                      <label>Last Name:</label>
                      <input 
                        autoComplete={'rand0mstr1nG'} 
                        type='text' 
                        {...register('lastName', { required: true })} 
                        />
                      {errors.lastName ? <div className="error">Must enter last name.</div> : 
                      firebaseErrors ? <div className="error">Please checkbox entry.</div> : 
                      null }
                  </div>

                  <div className="formContainer">
                      <label>Email:</label>
                      <input 
                        autoComplete={'rand0mstr1nG'} 
                        type='email' 
                        {...register('email', { required: true })} 
                        />
                      
                      {errors.email ? <div className="error">Must enter email address.</div> : 
                      firebaseErrors ? <div className="error">Please checkbox entry.</div> : 
                      null }
                  </div>

                  <div className="formContainer">
                      <label>School:</label>
                      <input 
                        autoComplete={'rand0mstr1nG'} 
                        type='text' 
                        {...register('SchoolName', { required: true })}  
                        />
                      
                      {errors.SchoolName ? <div className="error">Must enter school name.</div> : 
                      firebaseErrors ? <div className="error">Please checkbox entry.</div> : 
                      null }
                  </div>

                  

                  <div className="formContainer">
                  <p><strong>I am most interested in:</strong></p>
                    <label htmlFor="1" className={`radioButton`}>
                      <div className="radioButtonCheck"><Icons type="utility" icon="check" /></div>
                      <input
                        {...register('interested')}
                        type="checkbox"
                        name="interested"
                        value="sel-tracking"
                        id="1"
                      />{' '}
                      SEL Tracking
                    </label>

                    <label htmlFor="2" className={`radioButton`}>
                    <div className="radioButtonCheck"><Icons type="utility" icon="check" /></div>
                      <input
                        {...register('interested')}
                        type="checkbox"
                        name="interested"
                        value="behavior-tracking"
                        id="2"
                      />{' '}
                      Behavior Tracking
                    </label>

                    <label htmlFor="3" className={`radioButton`}>
                    <div className="radioButtonCheck"><Icons type="utility" icon="check" /></div>
                      <input
                        {...register('interested')}
                        type="checkbox"
                        name="interested"
                        value="student-goal-track"
                        id="3"
                      />{' '}
                      Student Goal Tracking
                    </label>

                    <label htmlFor="4" className={`radioButton`}>
                    <div className="radioButtonCheck"><Icons type="utility" icon="check" /></div>
                      <input
                        {...register('interested')}
                        type="checkbox"
                        name="interested"
                        value="group-goal-tracking"
                        id="4"
                      />{' '}
                      Group Goal Tracking
                    </label>

                    <label htmlFor="5" className={`radioButton`}>
                    <div className="radioButtonCheck"><Icons type="utility" icon="check" /></div>
                      <input
                        {...register('interested')}
                        type="checkbox"
                        name="interested"
                        value="pbis-rewarding-spending"
                        id="5"
                      />{' '}
                      PBIS Rewarding and Spending
                    </label>

                    

                    <label htmlFor="6" className={`radioButton`}>
                    <div className="radioButtonCheck"><Icons type="utility" icon="check" /></div>
                      <input
                        {...register('interested')}
                        type="checkbox"
                        name="interested"
                        value="school-wide-communication"
                        id="2"
                      />{' '}
                      School Wide Communication
                    </label>

                   
                    
                  </div>  

                

                  <Button type={'primary'} state={loading} click={handleSubmit(onSubmit)}>Stay Updated</Button>
                  
              </form> */}
              <div className={'panelForm'}>
               
                <br/>
                <p style={{textAlign: 'center'}}>Already have an account? <NavLink to="../signin">Sign In</NavLink></p>
              </div>
          </StartTemplate>

  )
}
