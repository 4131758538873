import React from "react";
import PropTypes from "prop-types";
import styles from './menuitem.module.css';
import Icons from '../../1atoms/icons/Icons';


export default function MenuItem({
  item: { id, label, state }, setActiveState
} ) {

  return (
    <button 
      onClick={() => setActiveState(label)} 
      id={`menuItem-${id}`} 
      className={`
        ${styles.menuItem} 
        ${label==='Reward' ? styles.reward : label==='Store' ? styles.store : label==='SEL' ? styles.sel : ''} 
        ${state==='ACTIVE' ? styles.active : ''}
      `}>
      <Icons size="medium" icon={label}/> <span className={styles.label}>{label}</span>
    </button>
  );

}

MenuItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
  }),
};