import React from 'react';
import styles from './modal.module.css';

// Components
import Icons from '../../1atoms/icons/Icons';
import Button from '../button/Button';

export default function Modal({clear, title, modalid, children, button, required}) {
  return (
    
        <dialog id={`${modalid}`} className={styles.container}>

            <section className={styles.header}>
                <h2>{title}</h2>
                {required ? '' : 
                <form method="dialog">
                    <button onClick={clear}>
                        <Icons type='utility' icon='xmark' size='medium'/>
                    </button>
                </form>
                }
            </section>

            <section className={styles.body}>
                {children}
            </section>

            <section className={`${styles.footer} ${!button && styles.center} ${required && styles.center}`}>
            {required ? '' : 
                <form method="dialog">
                    <Button click={clear} type='secondary'>
                       {button ? 'Cancel' : 'Close'} 
                    </Button>
                </form>
            }

                {button}
            </section>
    
        </dialog>
    
  )
}
