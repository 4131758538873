import React, {useState, useEffect} from "react";
import styles from './card.module.css';
import Icons from '../../1atoms/icons/Icons';

import { ItemTypes } from '../../../context/constants';

import { useDrag } from 'react-dnd';

export default function Card(props) {



  // Student Card Selection
  const [isSelected, setSelected] = useState();

  function cardClick() {
    setSelected(!isSelected)
  }

  useEffect(() => {
    
    if (props.click===true) {
      setSelected(true); 
    } else if (props.click===false) {
      setSelected(false);
    } else if (props.selected===false) {
      // Currently being used to clear card selection about an action
      setSelected(false);
    } else if (props.selected===true) {
      setSelected(true);
    }
  }, [props.click, props.selected]);

// Student Card icon animation after action
const [isMessage, setMessage] = useState(false);

useEffect(() => {
  if (props.student===true && props.data.done === true) {

    let data = Object.values(props.data);

    if (data[props.id + 1]===true) {
      setMessage(true);
    }
  }
}, [props.data]);

//   if (props.student===true) {
//   const [{isDragging}, drag] = useDrag(() => ({
//     type: ItemTypes.KNIGHT,
//     collect: monitor => ({
//       isDragging: !!monitor.isDragging(),
//     }),
//   }))
// }
  
 
  return (
    
    <div className={styles.container}>
    {props.options}
    <article 
      
      id={props.cardid}
      onClick={props.student===true ? cardClick : null} 
      
      className={`
        card 
        ${styles.card} 
        
        ${props.student===true ? styles.student : null}
        ${props.panel===true ? styles.panel : null}
        ${isSelected===true ? styles.selected : null}
         
        ${
          props.state==='Reward' ? styles.reward : 
          props.state==='Store' ? styles.store : 
          props.state==='SEL' ? styles.sel : 
        ''}
      `}>

      {isMessage===true && <div className={styles.iconCircle}><Icons size="medium" icon='Reward'/></div>}

      {props.children}
       
    </article>
    </div>
    
  );
}
