import React, {useEffect, useState} from 'react'
import styles from './modaledititem.module.css';

import { useForm } from 'react-hook-form';
import imageCompression from 'browser-image-compression';

// Firebase
import { db } from "../../../firebase/firebase";
import { updateDoc, doc ,onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Components
import Button from '../../2molecules/button/Button'
import Modal from '../../2molecules/modal/Modal'




export default function ModalEditItem({item}) {

  
    
    const [previewImage, setPreviewImage] = useState('');
    const [uploadImage, setUploadImage] = useState('');
    const [nameImage, setNameImage] = useState('');
    const storage = getStorage();

    const [loading, setLoading] = useState('');

    const { register, handleSubmit, formState: {errors}, setValue} = useForm();

    // Image Preview
    const changeHandler = (e) => {
     
      let preview = URL.createObjectURL(e.target.files[0]);
      setPreviewImage(preview);
      let upload = e.target.files[0];
      setUploadImage(upload);
  }

    // Set Existing Information In Form
    useEffect(() => {
        if (item) {

            const itemPath = doc(db, `schools/ZrewvPrq457M6f8AVI0T/stores/teachers/${item.data.addedbyid}/${item.id}`);
            const unsub = onSnapshot(itemPath, (doc) => {
            if (doc.exists) {
                
                const docData = doc.data();
                setPreviewImage(docData.itemimg);
                [
                {name: 'label', value: docData.label},
                {name: 'cost', value: docData.cost},
                {name: 'stock', value: docData.stock},
                ].forEach(({name, value}) => setValue(name, value))
            }
  
            });
            return unsub; 
        }  
    }, [item]);

     // Update Information
     const onSubmit = (async (values) => {
        setLoading('loading');
        try {
          const itemPath = new doc(db, `schools/ZrewvPrq457M6f8AVI0T/stores/teachers/${item.data.addedbyid}/${item.id}`);
          if (uploadImage) {  
            const teacherStoreStorageRef = ref(storage, `ZrewvPrq457M6f8AVI0T/stores/${item.data.addedbyid}/${uploadImage.name}`);

            //reduce
            const reduceOptions = {
              maxSizeMB: .3,
              maxWidthOrHeight: 600,
              useWebWorker: true
            }

            imageCompression(uploadImage, reduceOptions).then(function (compressedFile){
                const uploadItemImage = uploadBytesResumable(teacherStoreStorageRef, compressedFile);
              setNameImage(uploadImage.name); 
              uploadItemImage.on('state_changed', (snapshot) => {
                  console.log('uploaded');
              },(error) => {
                  console.log(error);
              }, async () => {
                getDownloadURL(uploadItemImage.snapshot.ref).then((downloadURL) => {
                    const formNewItem = {...values};
                    formNewItem.itemimg = downloadURL;
                    formNewItem.itemimgname = nameImage;

                    updateDoc(itemPath, formNewItem);

                });
              });
            })

            
            
             
          } else {
            
            const formSignUpData = {...values};
            await updateDoc(itemPath, formSignUpData);  
          }
          
        } catch(error) {
          console.log(error);
        }
        setPreviewImage('');
        setUploadImage('');
        setLoading('');
        window.editItemModal.close();
        })


  return (
    <Modal 
    title="Edit Item" 
    modalid='editItemModal'
    button={<Button type={'primary'} click={ handleSubmit(onSubmit) } state={loading}>Save</Button>}
    >
    
        <form className="panelForm" autoComplete={"off"}>
            <div className="formContainer">
              <label>Label:</label>
              <input
               
                type='text' 
                name='label'
                {...register('label', { required: true })} 
              />
              {errors.label ? <div className="error">Must enter a label.</div> :
              null }
            </div>

            <div className="formContainer">
              <label>Cost:</label>
              <input
               
                type='number' 
                name='cost'
                {...register('cost', { required: true })} 
              />
              {errors.cost ? <div className="error">Must enter a cost.</div> :
              null }
            </div>

            <div className="formContainer">
              <label>Stock:</label>
              <input
               
                type='number' 
                name='stock'
                {...register('stock', { required: true })} 
              />
              {errors.stock ? <div className="error">Must enter a stock.</div> :
              null }
            </div>



            <div id="imagePreview" className="formContainer">

              <div className={styles.buttonImageContainer} >
                <Button type2='button' class={styles.imageButton}>
                  <label className={styles.imageLabelButton} htmlFor="file-upload2">
                      Change Image
                  </label>
                  <input type='file' id="file-upload2" className={styles.inputFile} onChange={changeHandler}/>
                </Button> 
              </div>

              {previewImage && <div className={styles.imagePreview} style={{ backgroundImage: `url(${previewImage})` }} ></div>}    

            </div>         
        </form>
    
    </Modal>
  )
}
