import { useState, useEffect } from 'react';

// Firebase
import { db } from "../firebase/firebase";
import { doc, onSnapshot  } from 'firebase/firestore';

export function useEarlyAccessStatus() {

    const [earlyAccess, setEarlyAccess] = useState(false);
    const [checkingAccess, setCheckingAccess] = useState(true);

    // Application Settings
    useEffect(() => {
    
    const appPath = doc(db, `application/settings`);
    const unsub = onSnapshot(appPath, (doc) => {
    if (doc.exists) {
      const docData = doc.data();
      if (docData.earlyaccess==='Off') {
        setEarlyAccess(false)
      } else {
        setEarlyAccess(true)
      }
      setCheckingAccess(false)
    }

    });
    return unsub; 

    }, []);

    return {earlyAccess, checkingAccess}

}
