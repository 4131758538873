import React, {useEffect, useState} from 'react';
import styles from './buttonoptions.module.css';

export default function ButtonOptions2( props ) {

  let id = props.id;
  let type = props.type;
  let studentOptions = type + id;

//  const [student, setStudent] = useState('')

  // setStudent(studentOptions);

// useEffect(() => {
//   if (props.id) {

//   let studentid = props.id;
//   let studentOptions = 'optionsStudent' + studentid;
//   let studentButton = 'button' + studentid;
//   setStudent(studentOptions);

    
//       window.addEventListener('click', function(e){  
        
//         if (document.getElementById(studentOptions).contains(e.target) || document.getElementById(studentButton).contains(e.target)){

//         } else {
//           document.getElementById(studentOptions).removeAttribute('open');
//         }
      
//       });

//   }
// }, [props])

  

  return (
    
    <div id={studentOptions} className={`${styles.container}`}>
       {props.children}
    </div>
    
  )
}
