import React, { useEffect, useState } from "react";
import styles from './mainpanel.module.css';

import TypeSelection from "../../3organisms/typeselection/TypeSelection";

// Drag and Drop
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// Firebase
import { db } from "../../../firebase/firebase";
import {collection, onSnapshot, where, query, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import Tutorial from "../../2molecules/tutorial/Tutorial";


export default function MainPanel({type, cards, panel}) {

   // Groups
   const auth = getAuth();
   const [groupListing, setGroupListing] = useState([]);
 
   useEffect(() => {
     const q = query(collection(db, `teachers/${auth.currentUser.uid}/groups/`));
     const unsubscribe = onSnapshot(q, (querySnapshot) => {
     const groups = [];
     querySnapshot.forEach((doc) => {
   
         groups.push({data: doc.data()});
         setGroupListing(groups);
         
     });
     return unsubscribe;
    
     });
     
   }, []);

  return (
    <DndProvider backend={HTML5Backend}>
    <Tutorial modalid={'tutorial'}/>
    
      <TypeSelection groups={groupListing} />
      <main className={`${type==='Feed' && styles.feedSection} ${styles.mainSection}`}>
        <section className={styles.cardGroup}>
          {/* Student List Component */}
            {cards}
        </section>    
        <section className={styles.mainPanel}>
          {/* Panel Action Component */}
          {panel}
        </section>
    </main>
   </DndProvider>
  );
}
