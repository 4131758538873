import React from "react";
import styles from './logo.module.css';
export default function Logo({state, size, style}) {

  return (
    <div 
      className={
        
      `${style} ${
        state === "fullLogo" ? styles.fullLogo : 
        state === "fullWhiteLogo" ? styles.fullWhiteLogo : 
        state === "iconOnly" ? styles.iconOnly : 
        null
      }
      ${
        size === "medium" ? styles.medium : 
        size === "large" ? styles.large : 
        null
      }
      `
      }>

      {state === "fullLogo" && (
        
        <svg viewBox="0 0 398.39 110.75">
          <path className={styles.color1} d="M2.29,63.74c-1.49-1.23-2.29-2.9-2.29-4.75,0-3.34,2.73-6.07,6.15-6.07,1.23,0,2.46,.35,4.04,1.49,2.29,1.58,5.36,2.9,8.53,2.9,2.11,0,3.87-.44,3.87-1.93,0-1.06-.88-1.5-2.55-2.2l-4.92-2.02c-8-3.25-12.75-6.86-12.75-14.16,0-8.79,6.95-13.89,17.06-13.89,4.84,0,10.02,1.32,13.8,3.87,1.85,1.23,2.9,3.17,2.9,5.19,0,3.43-2.73,6.16-6.07,6.16-1.23,0-2.46-.53-3.52-1.14-2.29-1.32-4.57-2.11-7.03-2.11-1.93,0-3.08,.44-3.08,1.67,0,1.14,.97,1.58,2.99,2.37l4.4,1.76c7.83,3.17,12.84,6.42,12.84,13.8,0,9.23-7.74,14.6-18.2,14.6-6.33,0-12.04-2.02-16.18-5.54Z"/>
          <path className={styles.color1} d="M37.89,46.25c0-12.92,10.46-23.04,24-23.04,7.12,0,14.33,3.43,17.58,8.44,.97,1.5,1.41,2.73,1.41,4.04,0,3.69-2.99,6.68-6.68,6.68-2.29,0-3.96-.7-5.19-2.2-1.67-2.02-4.13-3.08-6.95-3.08-5.28,0-8.7,3.6-8.7,9.14s3.43,9.14,8.7,9.14c2.64,0,5.01-1.06,6.95-3.08,1.58-1.58,3.08-2.2,5.19-2.2,3.69,0,6.68,2.99,6.68,6.68,0,1.41-.35,2.55-1.14,3.78-3.78,5.63-10.11,8.7-17.85,8.7-13.54,0-24-10.11-24-23.04Z"/>
          <path className={styles.color1} d="M128.19,41.15v20.49c0,4.4-3.25,7.65-7.56,7.65s-7.56-3.25-7.56-7.65v-18.29c0-3.87-2.64-6.51-6.42-6.51s-6.33,2.64-6.33,6.51v18.29c0,4.4-3.25,7.65-7.56,7.65s-7.56-3.25-7.56-7.65V8.88c0-4.4,3.25-7.65,7.56-7.65s7.56,3.25,7.56,7.65V27.34c2.99-2.64,6.95-4.13,11.43-4.13,9.85,0,16.44,7.21,16.44,17.94Z"/>
          <path className={styles.color1} d="M179.89,46.25c0,12.84-10.55,23.04-24,23.04s-23.91-10.11-23.91-23.04,10.46-23.04,23.91-23.04,24,10.2,24,23.04Zm-32.44,0c0,5.45,3.43,9.14,8.53,9.14s8.44-3.87,8.44-9.14-3.6-9.14-8.44-9.14-8.53,3.69-8.53,9.14Z"/>
          <path className={styles.color1} d="M229.74,46.25c0,12.84-10.55,23.04-24,23.04s-23.91-10.11-23.91-23.04,10.46-23.04,23.91-23.04,24,10.2,24,23.04Zm-32.44,0c0,5.45,3.43,9.14,8.53,9.14s8.44-3.87,8.44-9.14-3.6-9.14-8.44-9.14-8.53,3.69-8.53,9.14Z"/>
          <path className={styles.color1} d="M233.87,61.63V8.88c0-4.4,3.17-7.65,7.56-7.65s7.56,3.25,7.56,7.65V61.63c0,4.4-3.25,7.65-7.56,7.65s-7.56-3.25-7.56-7.65Z"/>
          <path className={styles.color3} d="M324.96,30.51c0,1.32-.35,2.64-.97,4.04l-14.51,29.98c-1.49,3.08-3.87,4.75-6.86,4.75s-5.36-1.67-6.86-4.75l-7.56-16.27-7.56,16.27c-1.49,3.08-3.87,4.75-6.86,4.75s-5.36-1.67-6.86-4.75l-14.51-29.98c-.62-1.41-.97-2.73-.97-4.04,0-4.04,3.25-7.3,7.3-7.3,2.81,0,5.28,1.5,6.51,3.96l8.88,18.29,6.77-16.44c1.58-3.87,4.04-5.8,7.3-5.8s5.71,1.93,7.3,5.8l6.77,16.44,8.88-18.29c1.23-2.46,3.69-3.96,6.51-3.96,4.04,0,7.3,3.25,7.3,7.3Z"/>
          <path className={styles.color2} d="M347.75,8.7c0,4.84-4.13,8.62-9.06,8.62s-8.97-3.78-8.97-8.62,4.04-8.7,8.97-8.7,9.06,3.78,9.06,8.7Zm-16.53,52.93V30.86c0-4.4,3.17-7.65,7.56-7.65s7.56,3.25,7.56,7.65v30.77c0,4.4-3.17,7.65-7.56,7.65s-7.56-3.25-7.56-7.65Z"/>
          <path className={styles.color4} d="M398.39,46.25c0,12.84-10.55,23.04-24,23.04s-23.91-10.11-23.91-23.04,10.46-23.04,23.91-23.04,24,10.2,24,23.04Zm-32.44,0c0,5.45,3.43,9.14,8.53,9.14s8.44-3.87,8.44-9.14-3.6-9.14-8.44-9.14-8.53,3.69-8.53,9.14Z"/>
          <path className={styles.color1} d="M217.61,75.71c-2.63,0-5.1,1.22-6.72,3.3-6.96,8.97-17.84,14.74-30.07,14.74s-23.11-5.77-30.07-14.74c-1.61-2.08-4.09-3.3-6.72-3.3h0c-7.04,0-11.08,8.1-6.77,13.66,10.07,13.01,25.83,21.38,43.56,21.38s33.49-8.38,43.56-21.38c4.31-5.56,.27-13.66-6.77-13.66h0Z"/>
        </svg>  
      )}

      {state === "fullWhiteLogo" && (
        
        <svg viewBox="0 0 398.39 110.75">
          <path className={styles.color11} d="M2.29,63.74c-1.49-1.23-2.29-2.9-2.29-4.75,0-3.34,2.73-6.07,6.15-6.07,1.23,0,2.46,.35,4.04,1.49,2.29,1.58,5.36,2.9,8.53,2.9,2.11,0,3.87-.44,3.87-1.93,0-1.06-.88-1.5-2.55-2.2l-4.92-2.02c-8-3.25-12.75-6.86-12.75-14.16,0-8.79,6.95-13.89,17.06-13.89,4.84,0,10.02,1.32,13.8,3.87,1.85,1.23,2.9,3.17,2.9,5.19,0,3.43-2.73,6.16-6.07,6.16-1.23,0-2.46-.53-3.52-1.14-2.29-1.32-4.57-2.11-7.03-2.11-1.93,0-3.08,.44-3.08,1.67,0,1.14,.97,1.58,2.99,2.37l4.4,1.76c7.83,3.17,12.84,6.42,12.84,13.8,0,9.23-7.74,14.6-18.2,14.6-6.33,0-12.04-2.02-16.18-5.54Z"/>
          <path className={styles.color11} d="M37.89,46.25c0-12.92,10.46-23.04,24-23.04,7.12,0,14.33,3.43,17.58,8.44,.97,1.5,1.41,2.73,1.41,4.04,0,3.69-2.99,6.68-6.68,6.68-2.29,0-3.96-.7-5.19-2.2-1.67-2.02-4.13-3.08-6.95-3.08-5.28,0-8.7,3.6-8.7,9.14s3.43,9.14,8.7,9.14c2.64,0,5.01-1.06,6.95-3.08,1.58-1.58,3.08-2.2,5.19-2.2,3.69,0,6.68,2.99,6.68,6.68,0,1.41-.35,2.55-1.14,3.78-3.78,5.63-10.11,8.7-17.85,8.7-13.54,0-24-10.11-24-23.04Z"/>
          <path className={styles.color11} d="M128.19,41.15v20.49c0,4.4-3.25,7.65-7.56,7.65s-7.56-3.25-7.56-7.65v-18.29c0-3.87-2.64-6.51-6.42-6.51s-6.33,2.64-6.33,6.51v18.29c0,4.4-3.25,7.65-7.56,7.65s-7.56-3.25-7.56-7.65V8.88c0-4.4,3.25-7.65,7.56-7.65s7.56,3.25,7.56,7.65V27.34c2.99-2.64,6.95-4.13,11.43-4.13,9.85,0,16.44,7.21,16.44,17.94Z"/>
          <path className={styles.color11} d="M179.89,46.25c0,12.84-10.55,23.04-24,23.04s-23.91-10.11-23.91-23.04,10.46-23.04,23.91-23.04,24,10.2,24,23.04Zm-32.44,0c0,5.45,3.43,9.14,8.53,9.14s8.44-3.87,8.44-9.14-3.6-9.14-8.44-9.14-8.53,3.69-8.53,9.14Z"/>
          <path className={styles.color11} d="M229.74,46.25c0,12.84-10.55,23.04-24,23.04s-23.91-10.11-23.91-23.04,10.46-23.04,23.91-23.04,24,10.2,24,23.04Zm-32.44,0c0,5.45,3.43,9.14,8.53,9.14s8.44-3.87,8.44-9.14-3.6-9.14-8.44-9.14-8.53,3.69-8.53,9.14Z"/>
          <path className={styles.color11} d="M233.87,61.63V8.88c0-4.4,3.17-7.65,7.56-7.65s7.56,3.25,7.56,7.65V61.63c0,4.4-3.25,7.65-7.56,7.65s-7.56-3.25-7.56-7.65Z"/>
          <path className={styles.color33} d="M324.96,30.51c0,1.32-.35,2.64-.97,4.04l-14.51,29.98c-1.49,3.08-3.87,4.75-6.86,4.75s-5.36-1.67-6.86-4.75l-7.56-16.27-7.56,16.27c-1.49,3.08-3.87,4.75-6.86,4.75s-5.36-1.67-6.86-4.75l-14.51-29.98c-.62-1.41-.97-2.73-.97-4.04,0-4.04,3.25-7.3,7.3-7.3,2.81,0,5.28,1.5,6.51,3.96l8.88,18.29,6.77-16.44c1.58-3.87,4.04-5.8,7.3-5.8s5.71,1.93,7.3,5.8l6.77,16.44,8.88-18.29c1.23-2.46,3.69-3.96,6.51-3.96,4.04,0,7.3,3.25,7.3,7.3Z"/>
          <path className={styles.color22} d="M347.75,8.7c0,4.84-4.13,8.62-9.06,8.62s-8.97-3.78-8.97-8.62,4.04-8.7,8.97-8.7,9.06,3.78,9.06,8.7Zm-16.53,52.93V30.86c0-4.4,3.17-7.65,7.56-7.65s7.56,3.25,7.56,7.65v30.77c0,4.4-3.17,7.65-7.56,7.65s-7.56-3.25-7.56-7.65Z"/>
          <path className={styles.color44} d="M398.39,46.25c0,12.84-10.55,23.04-24,23.04s-23.91-10.11-23.91-23.04,10.46-23.04,23.91-23.04,24,10.2,24,23.04Zm-32.44,0c0,5.45,3.43,9.14,8.53,9.14s8.44-3.87,8.44-9.14-3.6-9.14-8.44-9.14-8.53,3.69-8.53,9.14Z"/>
          <path className={styles.color11} d="M217.61,75.71c-2.63,0-5.1,1.22-6.72,3.3-6.96,8.97-17.84,14.74-30.07,14.74s-23.11-5.77-30.07-14.74c-1.61-2.08-4.09-3.3-6.72-3.3h0c-7.04,0-11.08,8.1-6.77,13.66,10.07,13.01,25.83,21.38,43.56,21.38s33.49-8.38,43.56-21.38c4.31-5.56,.27-13.66-6.77-13.66h0Z"/>
        </svg>  
      )}
       
      {state === "iconOnly" && (
        <svg viewBox="0 0 97.77 87.54">
          <path className={styles.color1} d="M47.92,23.04c0,12.84-10.55,23.04-24,23.04S0,35.96,0,23.04,10.46,0,23.91,0s24,10.2,24,23.04Zm-32.44,0c0,5.45,3.43,9.14,8.53,9.14s8.44-3.87,8.44-9.14-3.6-9.14-8.44-9.14-8.53,3.69-8.53,9.14Z"/>
          <path className={styles.color1} d="M97.77,23.04c0,12.84-10.55,23.04-24,23.04s-23.91-10.11-23.91-23.04S60.31,0,73.77,0s24,10.2,24,23.04Zm-32.44,0c0,5.45,3.43,9.14,8.53,9.14s8.44-3.87,8.44-9.14-3.6-9.14-8.44-9.14-8.53,3.69-8.53,9.14Z"/>
          <path className={styles.color1} d="M85.64,52.5c-2.63,0-5.1,1.22-6.72,3.3-6.96,8.97-17.84,14.74-30.07,14.74s-23.11-5.77-30.07-14.74c-1.61-2.08-4.09-3.3-6.72-3.3h0c-7.04,0-11.08,8.1-6.77,13.66,10.07,13.01,25.83,21.38,43.56,21.38s33.49-8.38,43.56-21.38c4.31-5.56,.27-13.66-6.77-13.66h0Z"/>
        </svg>
      )}
    </div>
  );
}
