import React from "react";
import styles from './panel.module.css';

export default function Panel(props) {

  return (
    <article id={props.id} className={`${styles.panel} ${props.sticky===true && styles.sticky}`}>
      {props.children}   
    </article>
  );
}
