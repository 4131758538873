import React, {useState, useEffect} from 'react';

import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { useForm } from 'react-hook-form';

// Firebase
import { db } from "../../../firebase/firebase";
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, serverTimestamp, setDoc, updateDoc, arrayUnion, onSnapshot  } from 'firebase/firestore';

// Components
import Button from '../../2molecules/button/Button';
import StartTemplate from '../../4templates/startTemplate/StartTemplate';

export default function SignUpPage() {


  

  const navigate = useNavigate();
  const [loading, setLoading] = useState('');
  const [firebaseErrors, setFirebaseErrors] = useState(false);




  



  // Email and Password Sign up
  const { register, handleSubmit: handleSubmit, formState: {errors}} = useForm();

  const onSubmit = (async (values) => {
    setFirebaseErrors(false);
    setLoading('loading');
    try {
      const auth = getAuth();
      const userCrednetial = createUserWithEmailAndPassword(auth, values.email, values.password);
      const user = await userCrednetial;
      // console.log(user.user.uid);
      updateProfile(auth.currentUser, {displayName: values.firstName + ' ' + values.lastName})
      
      // Adding A New School Information
      // const schoolPath = await addDoc(collection(db, 'schools'), {
      //   school: values.SchoolName,
      //   teachers: arrayUnion(user.user.uid)
      // });
      // await updateDoc(schoolPath, {schoolid: schoolPath.id});

      // Temp Updating Motivating School Doc
      const schoolPath = doc(db, `schools/ZrewvPrq457M6f8AVI0T`);
      await updateDoc(schoolPath, {
        teachers: arrayUnion(user.user.uid)
      });

      // Teacher Information
      const formSignUpData = {...values};
      // Edit Values from Form
      delete formSignUpData.password; 
      delete formSignUpData.SchoolName; 
      formSignUpData.timestamp = serverTimestamp();
      formSignUpData.img = '../../../images/system-no-image.png';
      // Database Folder Names
      const teacherPath = new doc(db, `teachers/${user.user.uid}`);
      await setDoc(teacherPath, formSignUpData);

      await updateDoc(teacherPath, {
        // schoolsid: arrayUnion(schoolPath.id),
        // schools: arrayUnion(values.SchoolName),
        schoolsid: arrayUnion('ZrewvPrq457M6f8AVI0T'),
        schools: arrayUnion('Motivating Systems School'),
        login: 0,
      });

      if (user) {
        navigate('/feed');
      }

    } catch (error) {
      console.log(error);
      setFirebaseErrors(true);
    }
    setLoading('');
 
    }
)


  document.title = 'get started';

  return (

          <StartTemplate title={'get started'} >

              <form className="panelForm" autoComplete={"off"}>
                    
                  <div className="formContainer">
                      <label>First Name:</label>
                      <input 
                        autoComplete={'rand0mstr1nG'} 
                        type='text' 
                        {...register('firstName', { required: true })} 
                      />
                      {errors.firstName ? <div className="error">Must enter first name.</div> : 
                      firebaseErrors ? <div className="error">Please check entry.</div> : 
                      null }
                  </div>

                  <div className="formContainer">
                      <label>Last Name:</label>
                      <input 
                        autoComplete={'rand0mstr1nG'} 
                        type='text' 
                        {...register('lastName', { required: true })} 
                        />
                      {errors.lastName ? <div className="error">Must enter last name.</div> : 
                      firebaseErrors ? <div className="error">Please check entry.</div> : 
                      null }
                  </div>

                  <div className="formContainer">
                      <label>School:</label>
                      <input 
                        autoComplete={'rand0mstr1nG'} 
                        type='text' 
                        {...register('SchoolName', { required: true })}  
                        />
                      
                      {errors.SchoolName ? <div className="error">Must enter school name.</div> : 
                      firebaseErrors ? <div className="error">Please check entry.</div> : 
                      null }
                  </div>

                  <div className="formContainer">
                      <label>School Email:</label>
                      <input 
                        autoComplete={'rand0mstr1nG'} 
                        type='email' 
                        {...register('email', { required: true })} 
                        />
                      
                      {errors.email ? <div className="error">Must enter email address.</div> : 
                      firebaseErrors ? <div className="error">Please check entry.</div> : 
                      null }
                  </div>

                  <div className="formContainer">
                      <label>Password:</label>
                      <input 
                        autoComplete={'rand0mstr1nG'} 
                        type='password' 
                        {...register('password', { required: true })} 
                        />
                      
                      {errors.password ? <div className="error">Must enter password.</div> : 
                      firebaseErrors ? <div className="error">Please check entry.</div> : 
                      null }
                  </div>

                  <Button type={'primary'} state={loading} click={handleSubmit(onSubmit)}>Sign Up</Button>
                  
              </form>
              <div className={'panelForm'}>
                {/* <br/>
                <Button type={'primary'} >Continue With Google</Button> */}
                <br/>
                <p style={{textAlign: 'center'}}>Already have an account? <NavLink to="../signin">Sign In</NavLink></p>
              </div>
          </StartTemplate>

  )
}
