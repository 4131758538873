import React, {useState, useEffect} from 'react';
import styles from './studentlist.module.css';

import {useParams} from "react-router-dom";

// Form
import { useForm, useWatch, useFormState } from "react-hook-form";
import { useFormData } from "../../../context";

// Drag and Drop
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Components
import Icons from '../../1atoms/icons/Icons';
import CardStudent from '../../2molecules/cardstudent/CardStudent';
import Button from '../../2molecules/button/Button';
import CardActions from '../../2molecules/cardactions/CardActions';
import ButtonOptions2 from '../../2molecules/buttonoptions/ButtonOptions2';
import ButtonOptionItem from '../../2molecules/buttonoptionitem/ButtonOptionItem';
import ModalDeleteStudent from '../modalDeleteStudent/ModalDeleteStudent';
import ModalAddStudent from '../modalAddStudent/ModalAddStudent';
import ModalEditStudent from '../modalEditStudent/ModalEditStudent';
import ModalEditGroup from '../modalEditGroup/ModalEditGroup';
import ModalDeleteGroup from '../modalDeleteGroup/ModalDeleteGroup';

// Firebase
import { doc, deleteDoc, getDoc, updateDoc, arrayRemove, increment } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { getAuth} from "firebase/auth";
import Date from '../../1atoms/date/date';
import CardSectionSEL from '../../2molecules/cardsectionsel/cardSectionSEL';
import CardSectionPoints from '../../2molecules/cardsectionpoints/cardSectionPoints';
import CardSectionStore from '../../2molecules/cardsectionstore/cardSectionStore';
import Tutorial from '../../2molecules/tutorial/Tutorial';






export default function StudentList({students, state, group}) {


// const storage = getStorage();
let grouppara = useParams();
const auth = getAuth();



const [setCardOptions, setIsCardOptions] = useState('');


// Group States
const [editGroupID, setEditGroupID] = useState('');
const [deleteGroupID, setDeleteGroupID] = useState('');

// Group Options
function optionsGroup(grouppara) {
  if (group===true) {
    const groupOptions = 'optionsGroup' + grouppara.id;
    const options = document.getElementById(groupOptions);
    options.toggleAttribute('open');
    setIsCardOptions(options);
  }
}

// Edit Group
function editGroup(grouppara) {
  setCardOptions.removeAttribute('open');
  window.editGroupModal.showModal();
  setEditGroupID(grouppara);
}

// Delete Student
function deleteGroup(grouppara) {
  setCardOptions.removeAttribute('open');
  window.deleteGroupModal.showModal();
  setDeleteGroupID(grouppara)
}



// Student States
const [update, setUpdate] = useState('')
const [studentslist, setStudentsList] = useState(students);
const [editStudentID, setEditStudentID] = useState('');
const [removeStudentID, setRemoveStudentID] = useState('');

// Student Selection for Action
const [isSelected, setIsSelected] = useState('');
const { setFormValues, data } = useFormData();
const { handleSubmit, register, control, watch, reset, useFormContext, setValue } = useForm({ 
  mode: "all"
});

// Student Select All
function selectAll() {
  studentslist.map((student) => (     
    document.getElementById(student.id).checked = true,
    setValue(student.id, true)

  ))
}

function selectRandom() {

  let numberStudents = studentslist.length + 1;
  let randomNumber = Math.floor(Math.random() * numberStudents) + 1;

    studentslist.map((student, i) => {
      if (randomNumber===i) {
        document.getElementById(student.id).checked = true
        setValue(student.id, true)
      }
    })
  

 

}

useEffect(() => {

  if (data.done === true) {

    reset();
    setFormValues({done: false}); 

    var objects = new Map();

    studentslist.map((student, i) => {
      objects.set(student.id, false)
    });

    setFormValues(Object.fromEntries(objects))
    
    studentslist.map((student) => (   
      document.getElementById(student.id).checked = false,
      setValue(student.id, false)
    )) 

  } else {

    const subscription = watch((selected) => {
    setFormValues({...selected});
    })

    return () => {
    subscription.unsubscribe();
    reset();
    }

  }

}, [watch, data.done])



// Function If you set value before useWatch which is happening after send but doesn't seem to be the problem
// const useFormValues = () => {
//   const { getValues } = useFormContext();

//   return {
//     ...useWatch(),
//     ...setFormValues(getValues),
//   }
// }




// This works once but doesn't reset
// const subscription = useWatch({
//   control
// });

// useEffect(() => {
  
//   setFormValues(subscription);

//     if (data.done === true) {
//       reset();
//       setFormValues({done: false}); 
//     }

// }, [subscription, data.done]);




// useEffect(() => {
// setFormValues(useFormValues);
//   if (data.done === true) {
//     reset();
//     setFormValues({done: false});
//   }
// }, [data.done]);




// // On Change
// const onSubmit = ((values) => {
//   console.log({values});
//   setFormValues(values);
//   if (data.done === true) {
//     reset();
//     setFormValues({done: false});
//   }
// })



// Selected students in group
// const [selectStudents, setSelectStudents] = useState([]);

// useEffect(() => {
//   if (group==='all') {
//     setSelectStudents(students);
//   } else {
//     setSelectStudents([]);
//     students.forEach((student) => {
//       if (student.group.includes(group)) {
//         setSelectStudents((state => [...state, student]));
//       }   
//     })
//   }

//   if (data.done === true) {
//     console.log(JSON.stringify(data));
//     setIsSelected(false); 
//     setFormValues({done: false});
//     // Waits then sets state back to empty so the cards internal state useeffect will update after the change
//     setTimeout(() => {
      
//       setIsSelected(''); 
//     }, "1000")
//   }
  
// }, [group, students, data]);







// Student Options
function optionsStudent(student) {
  
  let studentid = student.id;
  const studentOptions = 'optionsStudent' + studentid;
  const options = document.getElementById(studentOptions);
  options.toggleAttribute('open');
  setIsCardOptions(options);
}

// Delete Student
function deleteStudent(student) {
  setCardOptions.removeAttribute('open');
  setUpdate(student.id);
  window.deleteStudentModal.showModal();
  setRemoveStudentID(student)
}

// Remove Student From Group
function removeGroupStudent(student) {

  setCardOptions.removeAttribute('open');
  const studentPath = doc(db, `students/${student.id}`);
  updateDoc(studentPath, {
      group: arrayRemove(grouppara.id)
  });
  const groupPath = doc(db, `teachers/${auth.currentUser.uid}/groups/${grouppara.id}`);
    updateDoc(groupPath, {
        students: arrayRemove(student.id)
    });
  setStudentsList(''); 
}

// Edit Student
function editStudent(student) {
  setCardOptions.removeAttribute('open');
  window.editStudentModal.showModal();
  setEditStudentID(student);
}

// View
const [setView, setIsView] = useState('');

// Student List
useEffect(() => {
  setStudentsList('');  
  setStudentsList(students);  
}, [students, update]);

// Sort by First Name 
if (students){
  // students.sort(function(a, b){return b.data.addedbywhen - a.data.addedbywhen});
  
  students.sort(function(a, b){return a.data.namefirst.localeCompare(b.data.namefirst)});

}

// Student Search
function handleChange(e) {
  
  let studentFilter = e.target.value.toLowerCase();
  const studentFiltering = students.filter(student => student.data.namefirst.toLowerCase().includes(studentFilter));
  console.log(studentFiltering);
  setStudentsList(studentFiltering);  
}

 // Date Formating
//  function doDaDate(date) {
//   if (date) {
//     let df = date.toDate();
//     let dfl = df.toLocaleDateString();
//     return dfl;
//   }
// }


return (
 <>
  
  

  <ModalAddStudent />
  <ModalEditStudent student={editStudentID}/>
  <ModalDeleteStudent student={removeStudentID} />

  <ModalEditGroup group={editGroupID}/>
  <ModalDeleteGroup group={deleteGroupID}/>
  
  
  <CardActions 
    left={
      <>
       
        {group!==true && 
        <Button purpose="create" type="icon" click={() => window.createStudentModal.showModal()}>
          <Icons type='utility' icon='plus' size='small'/>
        </Button>}
        
        <Button click={() => selectAll()}>All</Button>
        {state!=='SEL' && <Button click={() => selectRandom()}>Random</Button>}
      </>
    } 
    middle={
      <>
        <input placeholder="Find Student" onChange={handleChange}></input>
        <Icons type='utility' icon='magnifying-glass' size='small'/>
      </>
    } 
    right={
      <>
       <Button type="icon" click={() => setIsView(!setView)} >
          {setView===true ? <Icons type='utility' icon='list-ul' size='small'/> : <Icons type='utility' icon='grid' size='small'/>}
        </Button>
        {group===true &&
          <>
            <Button id={'button' + grouppara.id} type="icon" click={() => optionsGroup(grouppara)}>
              <Icons type='utility' icon='gear' size='small'/>
            </Button>
            
            <ButtonOptions2 type={'optionsGroup'} id={grouppara.id}  >
              <ButtonOptionItem click={() => editGroup(grouppara)} icon={'pencil'}>Edit Group</ButtonOptionItem>
              <ButtonOptionItem click={() => deleteGroup(grouppara)} icon={'xmark'}>Remove Group</ButtonOptionItem>
            </ButtonOptions2>
        </>
        }
    </>
    } 
  ></CardActions>


{studentslist && studentslist.length > 0 ?
<DndProvider backend={HTML5Backend}>
<form id='studentForm'>


<div className={`${setView===true ? styles.list : styles.grid}`}>
   {studentslist.map((student, i) => (
    
      <CardStudent student 
        state={state}
        key={i} 
        cardid={student.id} 
        data={data}
        options={
          
          <>
          
            <Button id={'button' + student.id} type2={'button'} click={() => optionsStudent(student)} type="icon" class={styles.options} >
              <Icons type='utility' icon='ellipsis-vertical' size='small'/> 
            </Button>

            <ButtonOptions2 type={'optionsStudent'} id={student.id}  >
              <ButtonOptionItem click={() => editStudent(student)} icon={'pencil'}>Edit Student</ButtonOptionItem>
              {group===true && <ButtonOptionItem click={() => removeGroupStudent(student)} icon={'xmark'}>Remove From Group</ButtonOptionItem>}
              <ButtonOptionItem click={()=> deleteStudent(student)} icon={'xmark'}>Delete Student</ButtonOptionItem>
            </ButtonOptions2>

          </>
        }
      >
      <input
          className={styles.studentCheckBox}
          type="checkbox"
          id={student.id}
          {...register(student.id)}
          key={student.id}

          // onChange={handleSubmit(onSubmit)}
        />
        
       <div className={styles.studentNameSection}>
        <img src={student.data.img}/> 
        <p><strong>{student.data.namefirst} {setView===true && <span><br/>{student.data.namelast}</span>}</strong></p>
      </div>
          
      
          {state==='Store' && <CardSectionStore student={student.data} view={setView} />}
          {state==='Reward' && <CardSectionPoints student={student.data} view={setView} />}
          {state==='SEL' && <CardSectionSEL student={student.data} view={setView} />}

      </CardStudent>   
    
      

   ))}
  </div>
  </form>
  </DndProvider> : 

 <div className={styles.noResults}>
  <p className="details">Drag <strong>Student Cards</strong> into your group from the <strong>All Students</strong> selection.<br/></p>
  <Button type='text' click={() => window.tutorial.showModal()}>Watch a quick tutorial</Button>
</div>

  }
</>
    
);


}