import React from 'react';

import styles from './cardaction.module.css';

export default function CardActions({left, middle, right, type}) {
  return (
    <section className={`${styles.container} ${type==='center' && styles.center}` }>

        <article className={styles.left}>
            {left}
        </article>

        <article className={styles.middle}>
            {middle}
        </article>
        
        <article className={styles.right}>
            {right}
        </article>

    </section>
  )
}
