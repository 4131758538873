import React from 'react'
import styles from './cardsectionstore.module.css';

// Components
import Date from '../../1atoms/date/date'
import Icons from '../../1atoms/icons/Icons'

export default function CardSectionStore({student, view}) {



  return (
    <div className={`${styles.section} ${view===true && styles.list}`}>
            <p className={`${styles.status}`}> 
              <Icons icon="Reward"/> 
              {student.points} 
              {view===true && <span className={`details ${styles.date}`}></span>}
            </p>
            

            {view===true && 
            <>
                {student.itemlabel && 
                    <p className={` details ${styles.comments}`}>
                        
                        <span className={styles.sectionOne}>
                        
                        <Date date={student.itemdate}/> {student.itemlabel} {student.itemcost}  <br/>
                            <span className={styles.name}>Staff: {student.itempurchaseteacher}</span>
                        </span>
                        
                    </p>
                }
            </>
            }

    </div>
  )
}
