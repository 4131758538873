import React, {useState, useEffect} from "react";
import styles from './admintemplate.module.css';

// Firebase
import { db } from "../../../firebase/firebase";
import {collection, onSnapshot, query} from "firebase/firestore";
import { getAuth } from "firebase/auth";

import TypeSelection from "../../3organisms/typeselection/TypeSelection";


export default function AdminTemplate(props) {

  // Groups
  const auth = getAuth();
  const [groupListing, setGroupListing] = useState([]);

  useEffect(() => {
    const q = query(collection(db, `teachers/${auth.currentUser.uid}/groups/`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const groups = [];
    querySnapshot.forEach((doc) => {
  
        groups.push({data: doc.data()});
        setGroupListing(groups);
        
    });
    return unsubscribe;
   
    });
    
  }, []);

  return (
    <div className={styles.container}>
    <TypeSelection groups={groupListing} dragdrop={false} />
    <main className={`${styles.mainSection}`}>
      {props.children}
   </main>
   </div>

  );
}
