import React from 'react';
import styles from './typeitem.module.css';

import { NavLink } from "react-router-dom";

export default function TypeItemno({group}, i) {


  return (
    <div key={i}>
        <NavLink to={`/student-groups/${group.data.idgroup}`} className={({ isActive }) => isActive ? styles.active : undefined } >
            <img src={group.data.img} alt={group.data.namegroup}/>
            <p>{group.data.namegroup}</p>

            <p className={styles.count}>{group.data.numbergroup}</p>
        </NavLink>
    </div>  
  )
}
