import React, {useState} from 'react';

import { useForm } from 'react-hook-form';

// components
import Modal from '../../2molecules/modal/Modal';
import Button from '../../2molecules/button/Button';

// Firebase
import { db } from "../../../firebase/firebase";
import { addDoc, updateDoc, setDoc, doc, collection, serverTimestamp, arrayUnion } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import AddImageProfile from '../addImageProfile/AddImageProfile';


export default function ModalAddStudent() {

  const { register, handleSubmit, formState: {errors}, reset} = useForm();
  const studentRef = doc(collection(db, "students"));
  const auth = getAuth();

  const [imageStatus, setImageStatus] = useState(0);
  const [profileImg, setProfileImg] = useState('../../../images/system-no-image.png');
  const [profileImgName, setProfileImgName] = useState('');
  const [newStudentID, setNewStudentID] = useState('');
  const [loading, setLoading] = useState('');

  const [firebaseErrors, setFirebaseErrors] = useState(false);

    const onSubmit = (async (values) => {

        const studentNewRef = doc(db, `students/${newStudentID}`);

        setFirebaseErrors(false);
        setLoading('loading');
        
        try {

            // New Student
            const formNewStudent = {...values};
            formNewStudent.addedbywhen = serverTimestamp();
            formNewStudent.addedbyid = auth.currentUser.uid;
            formNewStudent.addedbyname = auth.currentUser.displayName;
            formNewStudent.idschool = 'ZrewvPrq457M6f8AVI0T';
            formNewStudent.idstudent = newStudentID;
            formNewStudent.check = 'Nocheck';
            formNewStudent.img = profileImg;
            formNewStudent.imgname = profileImgName;
            formNewStudent.group = [];
            formNewStudent.points = 0;
            formNewStudent.pointsdaily = 0;
            formNewStudent.pointsperiod = 0;
            formNewStudent.pointsyear = 0;
       
            // const studentPath = new collection(db, `students/`);
            await setDoc(studentNewRef, formNewStudent);

            // const schoolPath = doc(db, 'schools/ZrewvPrq457M6f8AVI0T');
            // await updateDoc(schoolPath, {students: arrayUnion(studentRef.id),});

        } catch (error) {
            console.log(error);
            setFirebaseErrors(true);
        }
        setImageStatus(imageStatus + 1);
        reset(); 
        setLoading('');
        window.createStudentModal.close();
        
    })

  return (

    <Modal 
        title="Add Student" 
        modalid='createStudentModal'
        button={<Button type={'primary'} click={ handleSubmit(onSubmit) } state={loading}>Add Student</Button>}
    >

    <AddImageProfile imageStatus={imageStatus} setProfileImg={setProfileImg} setNewStudentID={setNewStudentID} studentid={studentRef.id} setProfileImgName={setProfileImgName} />
    
    <form className="panelForm" autoComplete={"off"}>

        <div className="formContainer">
            <label>First Name:</label>
            <input 
            
            type='text' 
            {...register('namefirst', { required: true })} 
            />
            {errors.namefirst ? <div className="error">Must enter first name.</div> : 
            firebaseErrors ? <div className="error">Please check entry.</div> : 
            null }
        </div>

        <div className="formContainer">
            <label>Last Name:</label>
            <input 
           
            type='text' 
            {...register('namelast', { required: true })} 
            />
            {errors.namelast ? <div className="error">Must enter last name.</div> : 
            firebaseErrors ? <div className="error">Please check entry.</div> : 
            null }
        </div>

    </form>

   

    </Modal>
  )
}
