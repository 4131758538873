import React, {useState} from 'react'

import { useForm } from 'react-hook-form';

// Components
import Button from '../../2molecules/button/Button'
import Modal from '../../2molecules/modal/Modal'

// Firebase
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { getStorage, ref, deleteObject } from "firebase/storage";

export default function ModalDeletePost({post}) {

    const storage = getStorage();
    const { handleSubmit } = useForm();
    const [loading, setLoading] = useState('');

     // Delete Post
     const onSubmit = (async () => {
        setLoading('loading');
        // Delete Image from Storage
         const postStorageRef = ref(storage, `posts/${post.id}/${post.data.postimgname}`);
  
        deleteObject(postStorageRef).then(() => {
          console.log('success'); 
        }).catch((error) => {
            console.log(error); 
        });


        // Delete Post
        deleteDoc(doc(db, `schools/ZrewvPrq457M6f8AVI0T/posts/${post.id}`));
        setLoading('');
        window.deletePostModal.close();

      })


  return (
    
    <Modal 
    title="Delete Post" 
    modalid='deletePostModal'
    button={<Button type={'primary'} click={ handleSubmit(onSubmit) } state={loading}>Delete</Button>} 
    >
    <p>This action will completely delete this post from the application. Are you sure you want to continue?</p>
    </Modal>
  )
}
